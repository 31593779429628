import React from 'react';
import { Language } from 'store/types';
import {
  formatDateFromTo,
  getCroppedImageUrl,
  isPastDate,
  prepareS3ResourceUrl,
} from 'utils';
import styles from './ReceptionBanner.module.scss';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/alert-circle.svg';
import { ReactComponent as HybridIcon } from 'assets/icons/hybrid.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import cn from 'classnames';
import t from 'i18n';
import moment from 'moment';
import AddToCalendarBtn from 'components/Common/AddToCalendarBtn';
import { CalendarEventType } from 'components/Common/AddToCalendarBtn/services';
import { useDiffTime, DiffTimeType } from 'hooks/useDiffTime';
import {
  BlueTheme,
  Theme,
} from 'components/Layout/AdvancedLayout/ThemeProvider';
import { isEmpty } from 'lodash';
import {
  CURRENT_CONGRESS_ID,
  CONGRESS_IDS,
  S3_FOLDER_URL,
} from '../../../config';
import classNames from 'classnames';

interface SingleEventCalProps {
  isCycle?: false;
  calendarEvent: CalendarEventType;
}

interface MultiEventCalProps {
  isCycle: true;
  icsFileUrl: string;
}
interface Props {
  isEventPreview?: boolean;
  title: string;
  subTitle?: React.ReactNode;
  urlBanner: string;
  startDate: string;
  endDate: string;
  replayExpiresIn?: string;
  language: Language;
  eventType?: 'WEBINAR' | 'HYBRID' | 'PRESENTIAL';
  replayText?: string;
  icsFileName?: string;
  extraLabel?: string;
  place?: string;
  eventId?: number;
  labels: {
    icon?: React.ReactNode;
    text?: React.ReactText;
    renderLabel?: () => React.ReactNode;
  }[];
  theme?: Theme;
  titleStyle?: React.CSSProperties;
  renderActions?: () => React.ReactNode;
  showCalendar?: boolean;
  isPremium?: boolean;
  isVirtual?: boolean;
  isLight?: boolean;
}

export const ReceptionBanner = ({
  isEventPreview,
  title,
  subTitle,
  urlBanner,
  startDate,
  endDate,
  replayExpiresIn,
  language,
  labels,
  eventType = 'WEBINAR',
  icsFileName,
  replayText,
  extraLabel,
  place,
  theme = BlueTheme,
  titleStyle,
  renderActions,
  eventId,
  showCalendar = true,
  isPremium,
  isVirtual,
  isLight = false,
  ...rest
}: Props & (SingleEventCalProps | MultiEventCalProps)) => {
  const banner = getCroppedImageUrl(urlBanner, undefined, 400);
  const bannerImgUrl = !isEmpty(banner)
    ? prepareS3ResourceUrl(S3_FOLDER_URL, banner)
    : '/img/event-banner-fallback-light.png';
  const diffTime = useDiffTime(startDate);
  const calData = rest.isCycle ? rest.icsFileUrl : rest.calendarEvent;
  const diffTimeReplay = useDiffTime(replayExpiresIn);
  const isPast = isPastDate(endDate);
  const isStarted = isPastDate(startDate);
  const isReplayExpired = !replayExpiresIn || isPastDate(replayExpiresIn);
  const isCurrentCongress = eventId && CONGRESS_IDS.includes(eventId);
  const premiumOfferYear = startDate.substr(0, 4);

  const getDiffTimeText = (diffTime: DiffTimeType) => {
    if (diffTime.days) {
      return `${diffTime.days} ${t('days')}`;
    } else if (diffTime.hours) {
      return `${diffTime.hours} ${t('hours')}`;
    } else if (diffTime.minutes) {
      return `${diffTime.minutes} ${t('minutes')}`;
    } else if (diffTime.seconds) {
      return `${diffTime.seconds} ${t('seconds')}`;
    }

    return '';
  };

  const renderProgressBar = (startDateTime: string, endDateTime: string) => {
    const startAt = moment(startDateTime);
    const endAt = moment(endDateTime);
    const now = moment();

    const totalTime = endAt.diff(startAt, 'seconds') ?? 1;
    const passedTime = now.diff(startAt, 'seconds');

    const percentage = now.isBefore(startAt)
      ? 0
      : Math.min(Math.floor((passedTime / totalTime) * 100), 100);

    return (
      <div className={styles.progress}>
        <div
          className={styles.progressBar}
          style={{ width: `${percentage}%`, background: theme.primaryColor }}
        />
      </div>
    );
  };

  const renderDiffTime = () => {
    if (isPast) {
      if (isReplayExpired || !replayExpiresIn) {
        return null;
      }

      return (
        <div className={styles.diffTime}>
          <div className={styles.text}>
            {t('Replay expires in')}:{' '}
            <strong>{getDiffTimeText(diffTimeReplay)}</strong>
          </div>
          <div className="m-t-xxs">
            {renderProgressBar(endDate, replayExpiresIn)}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.diffTime}>
        <div className={styles.text}>
          {isStarted ? (
            `${t('Ongoing')}:`
          ) : (
            <>
              {t('Starts in')}: <strong>{getDiffTimeText(diffTime)}</strong>
            </>
          )}
        </div>
        <div className="m-t-xxs">{renderProgressBar(startDate, endDate)}</div>
      </div>
    );
  };

  const renderLabels = () => {
    return (
      <div className={cn(styles.labels, 'm-b-s')}>
        {labels.map(({ icon, text, renderLabel }, index) => {
          if (renderLabel) {
            return <div key={index}>{renderLabel()}</div>;
          }

          return (
            <div key={index} className={styles.label}>
              <div className="flex-container align-middle">
                {icon}
                <div>{text}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAddToCalendar = () => {
    if (rest.isCycle) {
      return (
        <AddToCalendarBtn
          isMulti={true}
          icsFileUrl={calData as string}
          calendarName={title}
        />
      );
    }

    return <AddToCalendarBtn event={calData as CalendarEventType} />;
  };

  const renderEventMode = () => {
    if (isCurrentCongress && isVirtual) {
      return (
        <>
          <li className={'m-b-1'}>
            <EarthIcon
              width="12"
              height="12"
              fill="#29394D"
              className="m-r-xs"
            />
            <span>{t('Virtual')}</span>
          </li>
        </>
      );
    }

    if (eventId == CURRENT_CONGRESS_ID) {
      return null;
    }

    if (eventType === 'HYBRID') {
      return (
        <li>
          <HybridIcon
            width="14"
            height="14"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>{t('Virtual and Presential')}</span>
        </li>
      );
    }

    if (eventType === 'WEBINAR') {
      return (
        <li>
          <EarthIcon width="12" height="12" fill="#29394D" className="m-r-xs" />
          <span>{t('Webinar')}</span>
        </li>
      );
    }

    if (eventType === 'PRESENTIAL') {
      return (
        <li>
          <MapPinIcon
            width="12"
            height="12"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>{t('Presential')}</span>
        </li>
      );
    }

    return null;
  };

  const renderCongressDate = () => {
    return (
      <>
        <li className={isCurrentCongress ? 'm-b-1' : ''}>
          <div className="flex-container align-middle">
            <CalendarIcon
              width="12"
              height="12"
              fill="#29394D"
              className="m-r-xs"
            />

            <span>{formatDateFromTo(startDate, endDate, language)}</span>

            <div className="m-l-s">{showCalendar && renderAddToCalendar()}</div>
          </div>
        </li>
        {/*isCurrentCongress && (
          <li>
            <span style={{ marginLeft: '21px' }}>
              {t('congress_timeline_mai')}
            </span>
          </li>
        )*/}
      </>
    );
  };

  return (
    <div>
      <div
        className={styles.receptionBanner}
        style={{ backgroundImage: `url(${bannerImgUrl})` }}
      >
        {extraLabel && (
          <div className={styles.extra_labels}>
            <div className={styles.label}>
              <HelpIcon
                height="13px"
                width="13px"
                fill="#FFF"
                className="m-r-xs"
              />
              <span>{extraLabel}</span>
            </div>
          </div>
        )}
      </div>
      <div className="grid-x m-t-s">
        <div
          className={classNames(
            isEventPreview ? 'cell small-12  medium-10' : 'cell auto',
          )}
        >
          {renderLabels()}
          <div className={cn(styles.details, 'p-x-s m-b-s')}>
            {isPremium && (
              <div className={cn('m-b-xs', styles.premium)}>
                {t('premium offer').replace(':year', premiumOfferYear)}
              </div>
            )}
            <div className={cn('m-b-xs', styles.header)} style={titleStyle}>
              {title}
            </div>
            {subTitle && !isPremium && (
              <div className={cn('m-b-xs', styles.subTitle)}>{subTitle}</div>
            )}
            {!isPremium && (
              <div className={styles.infos}>
                <ul>
                  {renderCongressDate()}
                  {renderEventMode()}
                  {replayText && (
                    <li>
                      <PlayIcon
                        width="12"
                        height="12"
                        fill="#29394D"
                        className="m-r-xs"
                      />
                      <span>{replayText}</span>
                    </li>
                  )}
                  {!isEmpty(place?.split?.('contact')?.[0]) && (
                    <li>
                      {!isLight && (
                        <MapPinIcon
                          className="m-r-xs"
                          width="14"
                          height="14"
                          fill="#29394D"
                        />
                      )}
                      <span>{place?.split('contact')[0]}</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            isEventPreview ? 'cell small-12 medium-2' : 'p-x-s',
          )}
        >
          <div className="flex-container align-right">
            {!isPremium && renderDiffTime()}
          </div>
          {isEventPreview ? (
            <div className="flex-container align-right m-t-s">
              {renderActions?.()}
            </div>
          ) : (
            <div className="p-x-s">
              <div className="m-t-s">{renderActions?.()}</div>
              {/*{renderReplayLabel()}*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SubTitle = ({
  title,
  preTitle,
}: {
  preTitle: string;
  title: string;
}) => {
  return (
    <>
      <span>{preTitle}</span>&nbsp;
      <strong>{title}</strong>
    </>
  );
};

export default ReceptionBanner;

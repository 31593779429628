import React, { useEffect, useRef, useState } from 'react';
import styles from '../GuestAddress.module.scss';
import s from './AddAddress.module.scss';
import cn from 'classnames';
import {
  TTPInput,
  TTPRadioGroup,
  TTPSelectField,
} from 'components/Common/TTPForm';
import _ from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import t from 'i18n';
import * as yup from 'yup';
import { GuestAddress } from 'store/Guests/types';
import { cloneDeep, isEmpty } from 'lodash';
import { formatUen, parseBoolean } from 'utils';
import { Organization } from 'store/Address/types';
import { ReactComponent as SpinIcon } from 'assets/icons/tail-spin.svg';
import { BeforeMaskedStateChangeStates } from 'react-input-mask';
import { ReactComponent as BlFlag } from 'assets/icons/belgium-flag-icon.svg';
import { ReactComponent as NlFlag } from 'assets/icons/netherlands-flag-icon.svg';
import { ReactComponent as LuFlag } from 'assets/icons/luxembourg-flag-icon.svg';
import { ReactComponent as FrFlag } from 'assets/icons/french-flag-icon.svg';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from 'config';
import { useLanguage } from 'hooks/useLanguage';

type AddressData = Omit<GuestAddress, 'billingSignature'>;

interface Props {
  theme?: LayoutTheme;
  horizontalInputs?: boolean;
  defaultUen?: string;
  onSubmit: (data: AddressData, onFinally?: () => void) => any;
  onCancel: () => void;
  validateOrganizationNumber: (organizationNumber: string) => any;
}

export const AddAddress = ({
  theme,
  horizontalInputs = true,
  defaultUen,
  onSubmit,
  onCancel,
  validateOrganizationNumber,
}: Props) => {
  const [saving, setSaving] = useState(false);
  const [inputMask, setInputMask] = useState('');
  const [isValidNumber, setIsValidNumber] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const userInputRef = useRef('');
  let isValid = cloneDeep(isValidNumber);
  const [data, setData] = useState<AddressData>({
    billingCompanyNumber: formatUen(defaultUen?.toUpperCase()) ?? '',
    billingOrganization: '',
    billingStreet: '',
    billingPostalCode: '',
    billingSubjectToVAT: '0',
    billingAddress2: '',
    billingOrderNumber: '',
    billingCountry: '',
  });

  const flagsOptions = [
    {
      value: 'BE',
      label: <BlFlag width="20" height="16" style={{ zIndex: 10 }} />,
    },
    {
      value: 'FR',
      label: <FrFlag width="20" height="16" style={{ zIndex: 10 }} />,
    },
    {
      value: 'LU',
      label: <LuFlag width="20" height="16" style={{ zIndex: 10 }} />,
    },
    {
      value: 'NL',
      label: <NlFlag width="20" height="16" style={{ zIndex: 10 }} />,
    },
  ];
  const [selectedFlag, setSelectedFlag] = useState(flagsOptions[0]);
  const streetAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null,
  );
  const pcAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null,
  );
  const lng = useLanguage();
  const containsNumber = (inputString: string): boolean => {
    const hasNumber = /\d/.test(inputString);
    return hasNumber;
  };

  useEffect(() => {
    const uenInput = document.querySelector(
      `#input-billingCompanyNumber`,
    ) as HTMLInputElement;

    if (!isEmpty(defaultUen) && uenInput) {
      uenInput.focus();
      uenInput.blur();
    }
  }, [defaultUen]);

  useEffect(() => {
    setData((data) => ({
      ...data,
      ['billingStreet']: '',
      ['billingPostalCode']: '',
    }));
  }, [selectedFlag]);

  const [errors, setErrors] = useState<{ [P in keyof AddressData]: string }>({
    billingCompanyNumber: '',
    billingOrganization: '',
    billingStreet: '',
    billingPostalCode: '',
    billingSubjectToVAT: '0',
    billingAddress2: '',
    billingOrderNumber: '',
    billingCountry: '',
  });

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void | Promise<any>> => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await validationSchema.validate(data, { abortEarly: false });

      setSaving(true);
      data.billingCompanyNumber = data.billingCompanyNumber.replace(
        /[. ]/g,
        '',
      );
      onSubmit({ ...data, billingCountry: selectedFlag.value }, () =>
        setSaving(false),
      );
    } catch (err) {
      type Error = { path: keyof AddressData; message: string };
      const mapErrors: Error[] = err.inner.map(({ path, message }: Error) => ({
        path,
        message,
      }));

      setErrors((errors) => {
        const newErrors = { ...errors };
        mapErrors.forEach((e) => {
          newErrors[e.path] = e.message;
        });

        return newErrors;
      });
    }
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = name as keyof AddressData;

    if (!isEmpty(errors[fieldName])) {
      setErrors((errors) => ({ ...errors, [fieldName]: '' }));
    }

    if (value.substr(0, 2) === 'LU') {
      setInputMask('aa99999999');
    }

    if (value.substr(0, 2) === 'BE') {
      setInputMask('aa 9999.999.999');
    }

    setData((data) => ({ ...data, [fieldName]: value }));
  };

  const validateCompanyNumber = async (companyNumber: string) => {
    setIsFetching(true);
    await validateOrganizationNumber(companyNumber)
      .then((res: any) => {
        const organzationInfo = res.value.data as Organization;
        const address = organzationInfo.address.split('\n');
        if (organzationInfo) {
          isValid = cloneDeep(organzationInfo.isValid);
          setIsValidNumber(organzationInfo.isValid);
          (organzationInfo.name !== '---' ||
            organzationInfo.address !== '---') &&
            setData((data) => ({
              ...data,
              ['billingCompanyNumber']: companyNumber,
              ['billingOrganization']: organzationInfo.name
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              ['billingStreet']: address[0] ?? organzationInfo.address,
              ['billingPostalCode']: address[1] ?? '',
              ['billingSubjectToVAT']: '1',
            }));
        }
        if (isValid) {
          setIsFetching(false);
        } else {
          setData((data) => ({
            ...data,
            ['billingCompanyNumber']: companyNumber,
          }));
        }
      })
      .catch(() => {
        setIsFetching(false);
        setData((data) => ({
          ...data,
          ['billingOrganization']: '',
          ['billingStreet']: '',
          ['billingPostalCode']: '',
        }));
      });
  };

  const handleUserInput = (value: string) => {
    userInputRef.current = value;
    if (
      (value?.length === 12 && value?.includes('BE')) ||
      (value?.length === 10 && value?.includes('LU'))
    ) {
      if (value !== data.billingCompanyNumber)
        setData((data) => ({
          ...data,
          ['billingCompanyNumber']: value,
        }));
    }
  };

  const beforeMaskedValueChange = (states: BeforeMaskedStateChangeStates) => {
    const { nextState, currentState } = states;
    let { value, selection } = nextState;
    const { value: userInputValue } = currentState ?? {};

    if (
      userInputValue?.substr(0, 2) === 'BE' &&
      inputMask !== 'aa 9999.999.999'
    ) {
      setInputMask('aa 9999.999.999');
      value = userInputValue;
    }

    if (userInputValue?.substr(0, 1) === 'LU' && inputMask !== 'aa99999999') {
      setInputMask('aa99999999');
      value = userInputValue;
    }

    handleUserInput(userInputValue);

    return { value, selection: selection };
  };

  const onFieldBlur = async ({
    target: { value, name },
  }: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = name as keyof AddressData;
    if (fieldName == 'billingCompanyNumber' && value.length >= 10) {
      await validateCompanyNumber(value);
      if (!isValid) {
        await validateCompanyNumber(value).catch(() => {
          setIsFetching(false);
          setData((data) => ({
            ...data,
            ['billingOrganization']: '',
            ['billingStreet']: '',
            ['billingPostalCode']: '',
          }));
        });
        setIsFetching(false);
      }
    }
    validationSchema
      .validateAt(fieldName, data)
      .then(() => {
        if (!isEmpty(errors[fieldName])) {
          setErrors((errors) => ({ ...errors, [fieldName]: '' }));
        }
      })
      .catch(({ message }) => {
        setErrors((errors) => ({ ...errors, [fieldName]: message }));
      });
  };

  const inputFields: {
    name: keyof AddressData;
    label: string;
    isRequired?: boolean;
  }[] = [
    {
      name: 'billingCompanyNumber',
      label: _('inscription.billingCompanyNumber'),
      isRequired: parseBoolean(data.billingSubjectToVAT),
    },
    {
      name: 'billingOrganization',
      label: _('inscription.billingOrganization'),
    },
    {
      name: 'billingStreet',
      label: _('inscription.billingStreet'),
    },
    {
      name: 'billingPostalCode',
      label: _('inscription.billingPostalCode'),
    },
    {
      name: 'billingAddress2',
      label: _('inscription.billingAddress2'),
      isRequired: false,
    },
    {
      name: 'billingOrderNumber',
      label: _('inscription.billingOrderNumber'),
      isRequired: false,
    },
  ];

  const validationSchema = yup.object().shape({
    billingCompanyNumber: yup
      .string()
      .label(t('inscription.billingCompanyNumber'))
      .matches(/^BE[\d.\s]{10}|^\d{10}$|^LU[\d.\s]{8}|\./, {
        message: t('yup.invalid'),
        excludeEmptyString: true,
      })
      .when('billingSubjectToVAT', {
        is: (billingSubjectToVAT) => {
          return parseBoolean(billingSubjectToVAT);
        },
        then: yup.string().required(t('yup.required')),
      })
      .test('billingCompanyNumber', t('yup.invalid'), () => {
        return isValid || data['billingSubjectToVAT'] === '0';
      }),

    billingOrganization: yup
      .string()
      .label(t('inscription.billingOrganization'))
      .required(t('yup.required')),
    billingStreet: yup
      .string()
      .label(t('inscription.billingStreet'))
      .required(t('yup.required'))
      .test('billingStreet', t('yup.streetNumberMissing'), () => {
        return containsNumber(data['billingStreet']);
      }),

    billingAddress2: yup.string().label(t('inscription.billingAddress2')),

    billingOrderNumber: yup.string().label(t('inscription.billingOrderNumber')),

    billingPostalCode: yup
      .string()
      .label(t('inscription.billingPostalCode')) // Ex: '1360 Perwez',
      .required(t('yup.required'))
      .test('billingPostalCode', t('yup.missing_postal_code'), (value) =>
        /\d{4,9}/.test(value),
      )
      .test('billingPostalCode', t('yup.missing_locality'), (value) =>
        /[a-zA-Z]/.test(value),
      ),
    billingSubjectToVAT: yup
      .string()
      .oneOf(['1', '0'])
      .label(t('inscription.billingSubjectToVAT'))
      .required(t('yup.required')),

    billingCountry: yup.string(),
  });

  const handleFlagsSelectChange = (value: any) => {
    setSelectedFlag(value);
  };

  const handlePlaceSelect = (fieldName: string) => {
    if (fieldName == 'billingStreet') {
      const place = streetAutocompleteRef.current?.getPlace();
      if (place) {
        const street = place.address_components?.find((component) =>
          component.types.includes('route'),
        );
        const streetNumber = place.address_components?.find((component) =>
          component.types.includes('street_number'),
        );
        const postalCode = place.address_components?.find((component) =>
          component.types.includes('postal_code'),
        );
        const locality = place.address_components?.find((component) =>
          component.types.includes('locality'),
        );
        if (street && streetNumber) {
          setData((data) => ({
            ...data,
            ['billingStreet']: `${streetNumber.long_name}, ${street.long_name}`,
          }));
        } else {
          setData((data) => ({
            ...data,
            ['billingStreet']: place.name,
          }));
        }
        if (postalCode && locality) {
          setData((data) => ({
            ...data,
            ['billingPostalCode']: `${postalCode.long_name} ${locality.long_name}`,
          }));
        } else {
          setData((data) => ({
            ...data,
            ['billingPostalCode']: '',
          }));
        }
      }
    } else if (fieldName == 'billingPostalCode') {
      const place = pcAutocompleteRef.current?.getPlace();
      if (place) {
        const postalCode = place.address_components?.find((component) =>
          component.types.includes('postal_code'),
        );
        const locality = place.address_components?.find((component) =>
          component.types.includes('locality'),
        );
        if (postalCode && locality) {
          setData((data) => ({
            ...data,
            ['billingPostalCode']: `${postalCode.long_name} ${locality.long_name}`,
          }));
        } else {
          setData((data) => ({
            ...data,
            ['billingPostalCode']: place.name,
          }));
        }
      }
    }
  };

  return (
    <div className="m-b-s" style={{ position: 'relative' }}>
      {isFetching && (
        <span className={s.fetchingContainer}>
          <SpinIcon className={cn(s.fetching)} />
          {/* {isFetching && <span className={s.fetching_text}>Validating</span>} */}
        </span>
      )}
      {inputFields.map((field) =>
        field.name == 'billingStreet' || field.name == 'billingPostalCode' ? (
          <div
            className={
              field.name == 'billingStreet'
                ? horizontalInputs
                  ? s.streetContainer
                  : s.streetContainerVertical
                : undefined
            }
            key={field.name}
          >
            {field.name == 'billingStreet' && (
              <TTPSelectField
                options={flagsOptions}
                name="billingCountry"
                selected={selectedFlag}
                wrapperClassName={
                  horizontalInputs ? s.flagsSelect : s.flagsSelectVertical
                }
                onChange={handleFlagsSelectChange}
                value={selectedFlag.value}
              />
            )}
            <LoadScript
              googleMapsApiKey={GOOGLE_MAP_API_KEY}
              libraries={['places']}
              language={lng}
            >
              <Autocomplete
                onLoad={(autocomplete) => {
                  if (field.name == 'billingStreet') {
                    streetAutocompleteRef.current = autocomplete;
                  } else if (field.name == 'billingPostalCode') {
                    pcAutocompleteRef.current = autocomplete;
                  }
                }}
                onPlaceChanged={() => handlePlaceSelect(field.name)}
                options={{
                  componentRestrictions: { country: selectedFlag.value },
                }}
              >
                <TTPInput
                  key={field.name}
                  theme={theme}
                  name={field.name}
                  label={field.label}
                  required={field?.isRequired ?? true}
                  isHorizontal={horizontalInputs}
                  labelClassName={styles.inputLabel}
                  onChange={handleChange}
                  onBlur={onFieldBlur}
                  value={data[field.name]}
                  hasError={!isEmpty(errors[field.name])}
                  beforeMaskStateChange={beforeMaskedValueChange}
                  placeholder={
                    field.name == 'billingStreet'
                      ? _('inscription.billingStreet.placeholder')
                      : _('inscription.billingPostalCode.placeholder')
                  }
                  disabled={isFetching}
                  wrapperClassName={
                    field.name == 'billingStreet'
                      ? horizontalInputs
                        ? s.streetInputContainer
                        : s.streetInputContainerVertical
                      : horizontalInputs
                      ? s.inputsContainer
                      : s.inputsContainerVertical
                  }
                >
                  {!isEmpty(errors[field.name]) && <p>{errors[field.name]}</p>}
                </TTPInput>
              </Autocomplete>
            </LoadScript>
          </div>
        ) : (
          <TTPInput
            key={field.name}
            theme={theme}
            name={field.name}
            label={field.label}
            required={field?.isRequired ?? true}
            isHorizontal={horizontalInputs}
            labelClassName={styles.inputLabel}
            onChange={handleChange}
            onBlur={onFieldBlur}
            value={data[field.name]}
            hasError={!isEmpty(errors[field.name])}
            mask={field.name == 'billingCompanyNumber' ? inputMask : ''}
            isInputMask={field.name == 'billingCompanyNumber'}
            beforeMaskStateChange={beforeMaskedValueChange}
            placeholder={
              field.name == 'billingCompanyNumber'
                ? 'BE XXXX.XXX.XXX | LUXXXXXXXX'
                : ''
            }
            disabled={isFetching}
            wrapperClassName={
              horizontalInputs ? s.inputsContainer : s.inputsContainerVertical
            }
          >
            {!isEmpty(errors[field.name]) && <p>{errors[field.name]}</p>}
          </TTPInput>
        ),
      )}
      <TTPRadioGroup
        theme={theme}
        wrapperClassName="m-b-xs"
        name="billingSubjectToVAT"
        label=" "
        required={true}
        labelClassName={styles.inputLabel}
        onChange={handleChange}
        onBlur={onFieldBlur}
        options={[
          {
            value: '1',
            label: _('inscription.soumis_tva'),
          },
          {
            value: '0',
            label: _('inscription.non_soumis_tva'),
          },
        ]}
        value={data.billingSubjectToVAT}
      />
      <div className={cn(s.actions)}>
        <button
          className={s.cancel}
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          {t('Cancel')}
        </button>
        <button className={s.submit} onClick={handleSubmit}>
          {t('Add')}
        </button>
      </div>
    </div>
  );
};
export default AddAddress;

import { EventOption } from 'interfaces/EventOption';
import { EventPlan } from 'interfaces/EventPlan';
import { EventTag } from 'interfaces/EventTag';
import { Slot } from 'store/Slots/types';
import {
  NameWrappedAction,
  Organization,
  WithPaginationAndFilterState,
} from 'store/types';

export interface Event {
  id: number;
  uid: string;
  type: number;
  client: number;
  eventDate: string;
  configBag?: string;
  stages?: string;
  startDateTime: string;
  endDateTime: string;
  nameFr?: string | null;
  nameNl?: string | null;
  nameEn?: string | null;
  placeFr?: string | null;
  placeNl?: string | null;
  placeEn?: string | null;
  descriptionFr?: string | null;
  descriptionNl?: string | null;
  descriptionEn?: string | null;
  urlBannerFr?: string | null;
  urlBannerNl?: string | null;
  urlBannerEn?: string | null;
  accreditationHours: number;
  status: EVENT_STATUS;
  nonMemberPrice: number;
  memberPrice: number;
  speakers?: any;
  eventCycles?: Array<{
    id: number;
    number: number;
    cycle: number;
    isCyclePremium: boolean;
    isCycleSeason: boolean;
    event: number;
  }>;
  eventDates?: Array<{
    id: number;
    startDateTime: string;
    endDateTime: string;
  }>;
  multiDateIndex?: number;
  selectedDate?: string;
  isReplayable: number;
  isCongress?: number;
  isCertificateNotIncluded?: number;
  isVirtual?: number;
  slotsCount: number;
  playProgress?: number;
  fullWatch?: number | boolean;
  clientData?: Organization;
  languages: string;
  'speakers-abstract'?: {
    count: number;
    speakers: {
      id: string | number;
      eventId: string | number;
      firstName: string;
      lastName: string;
      pictureUrl: string;
      priority: string | number;
      headlineEn: string;
      headlineFr: string;
      headlineNl: string;
      typeForSlot?: { id: number; type: number };
      slots?: { id: number; type: number }[];
    }[];
  };
  'user-registered': boolean;
  replayStatus: EVENT_REPLAY_STATUS;
  dateEndOfReplay: string | null;
  slotReplayUrls?: Pick<
    Slot,
    | 'webinarUrlEn'
    | 'webinarReplayVideoFr'
    | 'webinarReplayVideoNl'
    | 'webinarReplayVideoEn'
  >;
  tag: EventTag[];
  eventClassificationRank: number;
  question: string;
  certificateStatus: number;
  isIncludedPremium: number;
  isMultiDate?: number;
}

export enum EVENT_TYPES {
  EVENT_FULL = 5,
  EVENT_QUICK = 6,
  EVENT_LIGHT = 7,
}

export enum EVENT_STATUS {
  UNKNOWN = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  SOON = 3,
  CLOSED = 4,
}

export enum EVENT_REPLAY_STATUS {
  REPLAY_STATUS_INACTIVE = 1,
  REPLAY_STATUS_ACTIVE = 2,
}

export enum EVENT_STAGES {
  SHOW_LANDING_PAGE = 'proShowHome',
  SHOW_RECEPTION_PAGE = 'proShowReception',
  SHOW_STAGE_PAGE = 'proShowScene',
  SHOW_TV_PAGE = 'proShowTV',
  SHOW_SESSIONS_PAGE = 'proShowSessions',
  SHOW_EXPO_PAGE = 'proShowExpo',
  SHOW_DOCS_PAGE = 'proShowDocs',
  SHOW_ADVERTISEMENTS_PAGE = 'proShowAnnonces',
  SHOW_SLACK_PAGE = 'proShowSlack',
  INSCRIPTION = 'inscription',
  PRINT_BADGE = 'printBadge',
  BLOCK_REGISTERED_MODIFY = 'blockRegisteredModification',
  SHOW_CERTIFICATE = 'showAttestation',
}

export enum EVENT_CERTIFICATE_STATUS {
  CERTIFICATE_EXAMEN_PASSED = 1,
  CERTIFICATE_PENDING_EXPORT = 2,
  CERTIFICATE_EXPORTED = 3,
}

export interface Book {
  id: number;
  backPictureUrl: string; // full path
  content?: string;
  ctaTitle: string;
  ctaUrl: string;
  location: string;
  pictureUrl: string;
  preTitle: string;
  title: string;
  type: CONFIG_BAG_BLOCK_TYPE;
}
export interface ConfigBag {
  blocks?: Book[];
  linkWebinarEn?: string;
  linkWebinarFr?: string;
  linkWebinarNl?: string;
  // membership?: { // Ex: membership
  //   type: 'groups';
  //   data: { groupsIn: ['386', '396', '408']; groupsEx: [] };
  // };
}

export enum CONFIG_BAG_BLOCK_TYPE {
  BOOK = 'book',
}

export interface EventPlanAndOptions {
  plans: EventPlan[];
  options: EventOption[];
  includedOptions: EventOption[];
  additionnalOptions: EventOption[];
  extraPlanOptions: EventOption[];
  isEventSoldOut: boolean;
}

export interface EventStats {
  currentEnd: string;
  currentStart: string;
  cycles: {
    current_cycles: string | number;
    future_cycles: string | number;
    past_cycles: string | number;
    replay_cycles: string | number;
    expired_cycles: string | number;
  };
  events: {
    current_events: string | number;
    future_events: string | number;
    past_events: string | number;
    replay_events: string | number;
    expired_events: string | number;
  };
  requestTime: string;
}

export interface UserRegisteredEventStats {
  requestTime: string;
  currentEnd: string;
  currentStart: string;
  events: {
    current_events: string | number;
    future_events: string | number;
    past_events: string | number;
    replay_events: string | number;
    expired_events: string | number;
    all_events: string | number;
    deja_vu_events: string | number;
  };
}

export interface EventState extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  statsFetching: boolean;
  statsFetched: boolean;
  userStatsFetching: boolean;
  userStatsFetched: boolean;
  eventIdsFetched: boolean;
  items: Event[];
  eventIds: number[];
  error: any;
  nbResult: number;
  stats: any;
  userStats: UserRegisteredEventStats | null;
  isEventFavoriteRemoved: boolean;
  isEventFavoriteAdded: boolean;
  eventFavoriteTab: string;
  previousRankOfEvent: number | undefined;
  updatedRankEventId: number | undefined;
  updatedRank: number | undefined;
}

export const RESET_EVENTS = 'RESET_EVENTS';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_PENDING = 'FETCH_EVENTS_PENDING';
export const FETCH_EVENTS_FULFILLED = 'FETCH_EVENTS_FULFILLED';
export const FETCH_EVENTS_REJECTED = 'FETCH_EVENTS_REJECTED';
export const FETCH_EVENT_IDS = 'FETCH_EVENT_IDS';
export const FETCH_EVENT_IDS_PENDING = 'FETCH_EVENT_IDS_PENDING';
export const FETCH_EVENT_IDS_FULFILLED = 'FETCH_EVENT_IDS_FULFILLED';
export const FETCH_EVENT_IDS_REJECTED = 'FETCH_EVENT_IDS_REJECTED';
export const FETCH_EVENTS_STATS = 'FETCH_EVENTS_STATS';
export const FETCH_EVENTS_STATS_PENDING = 'FETCH_EVENTS_STATS_PENDING';
export const FETCH_EVENTS_STATS_FULFILLED = 'FETCH_EVENTS_STATS_FULFILLED';
export const FETCH_EVENTS_STATS_REJECTED = 'FETCH_EVENTS_STATS_REJECTED';

export const FETCH_USER_REGISTERED_EVENTS_STATS =
  'FETCH_USER_REGISTERED_EVENTS_STATS';
export const FETCH_USER_REGISTERED_EVENTS_STATS_PENDING =
  'FETCH_USER_REGISTERED_EVENTS_STATS_PENDING';
export const FETCH_USER_REGISTERED_EVENTS_STATS_FULFILLED =
  'FETCH_USER_REGISTERED_EVENTS_STATS_FULFILLED';
export const FETCH_USER_REGISTERED_EVENTS_STATS_REJECTED =
  'FETCH_USER_REGISTERED_EVENTS_STATS_REJECTED';

export const DELETE_EVENT_FAVORITE = 'DELETE_EVENT_FAVORITE';
export const DELETE_EVENT_FAVORITE_PENDING = 'DELETE_EVENT_FAVORITE_PENDING';
export const DELETE_EVENT_FAVORITE_FULFILLED =
  'DELETE_EVENT_FAVORITE_FULFILLED';
export const DELETE_EVENT_FAVORITE_REJECTED = 'DELETE_EVENT_FAVORITE_REJECTED';

export const SAVE_EVENT_FAVORITE = 'SAVE_EVENT_FAVORITE';
export const SAVE_EVENT_FAVORITE_PENDING = 'SAVE_EVENT_FAVORITE_PENDING';
export const SAVE_EVENT_FAVORITE_FULFILLED = 'SAVE_EVENT_FAVORITE_FULFILLED';
export const SAVE_EVENT_FAVORITE_REJECTED = 'SAVE_EVENT_FAVORITE_REJECTED';

export const RESET_EVENT_FAVORITE = 'RESET_EVENT_FAVORITE';
export const RESET_EVENTS_FAVORITE = 'RESET_EVENTS_FAVORITE';
export const SET_EVENT_FAVORITE = 'SET_EVENT_FAVORITE';
export const SET_EVENT_CLASSIFICATION_RANK = 'SET_EVENT_CLASSIFICATION_RANK';

export const SAVE_EVENT_CLASSIFICATION_RANK = 'SAVE_EVENT_CLASSIFICATION_RANK';
export const SAVE_EVENT_CLASSIFICATION_RANK_PENDING =
  'SAVE_EVENT_CLASSIFICATION_RANK_PENDING';
export const SAVE_EVENT_CLASSIFICATION_RANK_FULFILLED =
  'SAVE_EVENT_FAVORITE_FULFILLED';
export const SAVE_EVENT_CLASSIFICATION_RANK_REJECTED =
  'SAVE_EVENT_FAVORITE_REJECTED';

export interface ResetEventFavoriteAction extends NameWrappedAction {
  type: typeof RESET_EVENT_FAVORITE;
}

export interface ResetEventsFavoriteAction extends NameWrappedAction {
  type: typeof RESET_EVENTS_FAVORITE;
}

export interface SetEventFavoriteAction extends NameWrappedAction {
  type: typeof SET_EVENT_FAVORITE;
  isEventFavoriteRemoved: boolean;
  isEventFavoriteAdded: boolean;
  eventFavoriteTab: string;
}

export interface SetEventClassificationRankAction extends NameWrappedAction {
  type: typeof SET_EVENT_CLASSIFICATION_RANK;
  previousRankOfEvent: number;
  updatedRankEventId: number;
  updatedRank: number;
}

export interface ResetEventsAction extends NameWrappedAction {
  type: typeof RESET_EVENTS;
}
export interface FetchEventsAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS
    | typeof FETCH_EVENTS_PENDING
    | typeof FETCH_EVENTS_FULFILLED
    | typeof FETCH_EVENTS_REJECTED;
  payload: any;
}
export interface FetchEventIdsAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENT_IDS
    | typeof FETCH_EVENT_IDS_PENDING
    | typeof FETCH_EVENT_IDS_FULFILLED
    | typeof FETCH_EVENT_IDS_REJECTED;
  payload: any;
}

export interface FetchEventsStatsAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS_STATS
    | typeof FETCH_EVENTS_STATS_PENDING
    | typeof FETCH_EVENTS_STATS_FULFILLED
    | typeof FETCH_EVENTS_STATS_REJECTED;
  payload: any;
}

export interface FetchUserRegisteredEventsStatsAction
  extends NameWrappedAction {
  type:
    | typeof FETCH_USER_REGISTERED_EVENTS_STATS
    | typeof FETCH_USER_REGISTERED_EVENTS_STATS_PENDING
    | typeof FETCH_USER_REGISTERED_EVENTS_STATS_FULFILLED
    | typeof FETCH_USER_REGISTERED_EVENTS_STATS_REJECTED;
  payload: any;
}

export interface deleteEventFavoriteAction extends NameWrappedAction {
  type:
    | typeof DELETE_EVENT_FAVORITE
    | typeof DELETE_EVENT_FAVORITE_PENDING
    | typeof DELETE_EVENT_FAVORITE_FULFILLED
    | typeof DELETE_EVENT_FAVORITE_REJECTED;
  payload: any;
}

export interface saveEventFavoriteAction extends NameWrappedAction {
  type:
    | typeof SAVE_EVENT_FAVORITE
    | typeof SAVE_EVENT_FAVORITE_PENDING
    | typeof SAVE_EVENT_FAVORITE_FULFILLED
    | typeof SAVE_EVENT_FAVORITE_REJECTED;
  payload: any;
}

export interface saveEventClassificationRankAction extends NameWrappedAction {
  type:
    | typeof SAVE_EVENT_CLASSIFICATION_RANK
    | typeof SAVE_EVENT_CLASSIFICATION_RANK_PENDING
    | typeof SAVE_EVENT_CLASSIFICATION_RANK_FULFILLED
    | typeof SAVE_EVENT_CLASSIFICATION_RANK_REJECTED;
  payload: any;
}

export type EventActionTypes =
  | FetchEventsAction
  | FetchEventIdsAction
  | ResetEventsAction
  | saveEventFavoriteAction
  | deleteEventFavoriteAction
  | ResetEventFavoriteAction
  | ResetEventsFavoriteAction
  | SetEventFavoriteAction
  | SetEventClassificationRankAction
  | saveEventClassificationRankAction
  | FetchEventsStatsAction
  | FetchUserRegisteredEventsStatsAction;

export interface ModalState<T = any> {
  isOpen: boolean;
  modalId: ModalIdType;
  data: T;
  onClose?: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export type ModalIdType =
  | ''
  | 'SESSION'
  | 'AD_CONFIRM'
  | 'INFO'
  | 'EventQuick'
  | 'CYCLE_DETAILS'
  | 'PAYMENT'
  | 'EVENT_FULL_REGISTRATION'
  | 'PACK_REGISTRATION'
  | 'SPEAKER'
  | 'CONFIRMATION'
  | 'OPTIONS_CONFIRMATION'
  | 'EVENT_POPUP'
  | 'WATCH'
  | 'WATCH_SEARCH'
  | 'EVENT_FREE_REGISTRATION'
  | 'EVENT_CANCEL_REGISTRATION';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export interface CloseModalAction {
  type: typeof CLOSE_MODAL;
}
export interface OpenModalAction {
  type: typeof OPEN_MODAL;
  modalId: ModalIdType;
  data: any;
  onClose?: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export type ModalActionTypes = OpenModalAction | CloseModalAction;

import { Organization } from 'store/types';
import { Reduction } from 'store/Guests/types';
export interface Cycle {
  id: number;
  status: CYCLE_STATUS;
  startDateTime: string;
  endDateTime: string;
  dateEndOfReplay: string;
  endOfReplayForcedDateTime: string;
  allEventsPrices: {
    member?: number;
    nonMember: number;
  };
  clien: number | null;
  clientId: number | null;
  contactEn: string | null;
  contactFr: string | null;
  contactNl: string | null;
  createdAt: string;
  descriptionEn: string | null;
  descriptionFr: string | null;
  descriptionNl: string | null;
  emailContactEn: string | null;
  emailContactFr: string | null;
  emailContactNl: string | null;
  languages: string;
  maxNumber: number;
  memberPrice: number;
  nameEn: string | null;
  nameFr: string | null;
  nameNl: string | null;
  nonMemberPrice: number;
  phoneNumberContactEn: string | null;
  phoneNumberContactFr: string | null;
  phoneNumberContactNl: string | null;
  pictureUrlEn: string | null;
  pictureUrlFr: string | null;
  pictureUrlNl: string | null;
  price_list: PriceCycle[];
  trainingMinutes: null;
  totalCertifiedHours: number;
  uid: string;
  eventsCount: number;
  eventCycles: EventCycle[];
  isReplayable: null | boolean | number;
  isPremium: null | boolean | number;
  isSeason: null | boolean | number;
  isAgreedByItaa: null | boolean | number;
  isAgreedByOBFG: null | boolean | number;
  isForcedDate: null | boolean | number;
  projectedEventsCount: number;
  client: number;
  clientData?: Organization;
  'user-registered': boolean;
  'speakers-abstract': {
    count: number;
    speakers: {
      id: string | number;
      eventId: string | number;
      firstName: string;
      lastName: string;
      pictureUrl: string;
      priority: string | number;
    }[];
  };
  reductions?: Reduction[];
  isCertificateNotIncluded?: number;
  question: string;
  monthlyMemberPrice: number;
  monthlyNonMemberPrice: number;
  type: number;
}

export enum CYCLE_STATUS {
  UNKNOWN = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  SOON = 3,
  CLOSED = 4,
}

interface PriceCycle {
  articleCode: string;
  codeIc: string;
  createdAt: string;
  journal: string;
  labelEn: string;
  labelFr: string;
  labelNl: string;
  objectId: number;
  pchVatMember: number;
  pchVatNonMember: number;
  rateBe: string;
  rateIc: string;
  type: string; // "cycle"
  updatedAt: string;
  vatCodeBe: string;
}

export interface EventCycle {
  id: number;
  number: number;
  cycle: number;
  event: number;
  eventsAbstract: {
    id: number;
    uid: string;
    endDateTime: string;
    nameEn: string;
    nameFr: string;
    nameNl: string;
    startDateTime: string;
    memberPrice: number;
    nonMemberPrice: number;
    accreditationHours: number;
    languages?: string;
    speakers?: {
      email: string;
      firstName: string;
      id: number | string;
      lastName: string;
      pictureUrl: string;
    }[];
    eventDates?: Array<{
      id: number;
      startDateTime: string;
      endDateTime: string;
    }>;
    selectedDate?: string;
    multiDateIndex?: number;
    isReplayable: number;
    isCertificateNotIncluded?: number;
    'user-registered': boolean;
    dateEndOfReplay: string | null;
    eventCycles?: Array<{
      id: number;
      number: number;
      cycle: number;
      isCyclePremium: boolean;
      isCycleSeason: boolean;
      event: number;
    }>;
    certificateStatus: number;
    isIncludedPremium: number;
  };
}

export type EventAbstract = EventCycle['eventsAbstract'];

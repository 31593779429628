import actions from './actions';
import * as api from './api';
import { Dispatch } from 'redux';
import { RootState, EventReducerNames, ResourceData } from 'store/types';
import { Event } from './types';
import { onError, onSuccess, separateEvents } from 'utils';
import { selectEvents } from './selectors';
import { Filter } from 'services/Filter';
import { uniq } from 'lodash';
import { selectToken } from 'store/Auth/selectors';

/**
 * Optimazing the number of requests sended to the Api
 */
const EventPromises: any = {
  /** The promise LIST is used also for SPONSORED AND RECOMMENDED */
  LIST: null,
  POPULAR: null,
  // SPONSORED: null,
  // RECOMMENDED: null
};

export const getEventIdsPromise = (
  state: RootState,
  type: 'LIST' | 'POPULAR',
) => {
  const {
    auth: { token: userToken, user, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  let promise: Promise<any> | null = EventPromises[type];

  if (!promise) {
    promise = api
      .getEventIds({
        token,
        userId: user?.id ?? 0,
      })
      .finally(() => {
        EventPromises.LIST = null;
      });
    EventPromises.LIST = promise;
  }

  return promise;
};

export const fetchEvents = (
  name: EventReducerNames = 'LIST',
  filters: Filter[] = [],
  fields?: string[],
  queryParams?: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const { page, pageSize, eventIds, eventIdsFetched } = selectEvents(
    state,
    name,
  );
  const token = userToken !== '' ? userToken : appToken.token;

  const fetchEventsAction = (ids: number[]) =>
    dispatch(
      actions.fetchEvents(
        name,
        api
          .getEvents({
            token,
            ids,
            page,
            pageSize,
            filters,
            fields,
            queryParams,
            sort: [{ property: 'eventDate', dir: 'DESC' }],
          })
          .then((res) => {
            const { data, nbResult } = res.data;
            return { data, nbResult };
          }),
      ),
    );

  if (eventIdsFetched && eventIds.length > 0) {
    return fetchEventsAction(eventIds);
  }

  return dispatch(
    actions.fetchEventIds(
      name,
      getEventIdsPromise(state, 'LIST').then((resp) => {
        const eventsIds = resp?.data?.data?.events ?? [];
        const eventsAdmIds = resp?.data?.data?.eventsAdm ?? [];
        const allIds = uniq([...eventsIds, ...eventsAdmIds]);

        if (allIds.length > 0) {
          fetchEventsAction(allIds);
        }
        return resp;
      }),
    ),
  );
};

export const fetchPopularEvents = () => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const ReducerName = 'POPULAR';
  const state = getState();
  const {
    auth: { token: userToken, user, appToken },
  } = state;
  const { page, pageSize, eventIds, eventIdsFetched } = selectEvents(
    state,
    ReducerName,
  );
  const token = userToken !== '' ? userToken : appToken.token;

  const fetchEventsAction = (ids: number[]) =>
    dispatch(
      actions.fetchEvents(
        ReducerName,
        api
          .getEvents({
            token,
            ids,
            page,
            pageSize,
            sort: [
              { property: 'eventDate', dir: 'DESC' },
              { property: 'id', fields: ids, dir: 'ASC' },
            ],
          })
          .then((res) => {
            const { data, nbResult } = res.data;
            const sortedEventsByPopularity: Event[] = [];
            const mappedEvents = data.reduce(
              (mappedEvents: any, event: Event) => {
                return { ...mappedEvents, [event.id]: event };
              },
              {},
            );

            ids.forEach((id: number) => {
              if (mappedEvents[id]) {
                sortedEventsByPopularity.push(mappedEvents[id]);
              }
            });
            const { past, upcoming } = separateEvents(sortedEventsByPopularity);

            return { data: [...upcoming, ...past], nbResult };
          }),
      ),
    );

  if (eventIdsFetched && eventIds.length > 0) {
    return fetchEventsAction(eventIds);
  }

  return dispatch(
    actions.fetchEventIds(
      ReducerName,
      api
        .getPopularEventIds({
          token,
          userId: user?.id ?? 0,
        })
        .then((resp) => {
          if (resp && resp.data && resp.data.data && resp.data.data.events) {
            fetchEventsAction(resp.data.data.events);
          }
          return resp;
        }),
    ),
  );
};

export const fetchEventsStats = (queryParams?: any) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const reducerName = 'LIST';
  const state = getState();
  const token = selectToken(state);

  return dispatch(
    actions.fetchEventsStats(
      reducerName,
      api.getEventsStats({ token, queryParams }),
    ),
  );
};

export const fetchUserRegisteredEventsStats = (queryParams?: any) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const reducerName = 'LIST';
  const state = getState();
  const token = selectToken(state);

  return dispatch(
    actions.fetchUserRegisteredEventsStats(
      reducerName,
      api.getUserRegisteredEventsStats({ token, queryParams }),
    ),
  );
};

export const deleteEventFavorite = (eventId: number, userId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.deleteEventFavorite(
      api
        .deleteEventFavorite({ token, userId, eventId })
        .then((res) => {
          const { data } = res.data;
          onSuccess('', { body: 'deleted_from_list' });
          return { data };
        })
        .catch(onError),
    ),
  );
};

export const saveEventFavorite = (eventId: number, userId: number) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveEventFavorite(
      api
        .saveEventFavorite({ token, userId, eventId })
        .then((res) => {
          const { data } = res.data;
          onSuccess('', { body: 'added_to_list' });
          return { data };
        })
        .catch(onError),
    ),
  );
};

export const saveEventClassificationRank = (
  updatedEventId: number,
  updatedRank: number,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveEventClassificationRank(
      api
        .saveEventClassificationRank({
          token,
          updatedEventId,
          updatedRank,
        })
        .then((res) => {
          const { data } = res.data;
          onSuccess();
          return { data };
        })
        .catch(onError),
    ),
  );
};

export default {
  fetchEvents,
  saveEventFavorite,
  deleteEventFavorite,
};

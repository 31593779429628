import Onboarding from 'components/Common/Onboarding';
import s from './RegistrationStepsHandler.module.scss';
import cn from 'classnames';
import OnboardingPage from 'components/Common/Onboarding/OnboardingPage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Coupon,
  COUPON_REDUCTION_TYPE,
  Guest,
  RegistredChoice,
} from 'store/Guests/types';
import t from 'i18n';
import StepPersonalData from '../Steps/StepPersonalData';
import StepPlan from '../Steps/StepPlan';
import StepOptions from '../Steps/StepOptions';
import StepAddress from '../Steps/StepAddress';
import StepPayment from '../Steps/StepPayment';
import { User } from 'store/Auth/types';
import { Language } from 'store/types';
import {
  filterCoupons,
  getInitialGuestData,
  GuestData,
  REGISTRATION_FULL_STEPS,
} from '../services';
import { Event, EventPlanAndOptions } from 'store/Events/types';
import { getSelectedCoupons, mergeWithGuestAddress, parseJson } from 'utils';
import { PLAN_OPTION_TYPE } from 'interfaces/EventPlan';
import { difference, isEmpty } from 'lodash';
import { useComponentDidMount } from 'hooks/useComponentDidMount';
import { EventOption } from 'interfaces/EventOption';
import { useSelectedOptions } from '../hooks/useSelectedOptions';
import { useCurrentPlan } from '../hooks/useCurrentPlan';
import { useSelectedCoupons } from '../hooks/useSelectedCoupons';
import { useTotalPrice } from '../hooks/useTotalPrice';
import { useExtraOptions } from '../hooks/useExtraOptions';
import { EVENT_FULL_ALLOWED_COUPONS } from 'config';
import { useQuery } from 'hooks/useQuery';

export interface RegistrationStepsHandlerProps {
  event: Event;
  user: User;
  language: Language;
  guest: Guest;
  coupons: Coupon[];
  checkedCoupon: Coupon | null;
  plansAndOptions: EventPlanAndOptions | null;
  fetchGuestData: (
    eventId: string | number,
    userId: string | number,
    codeCoupon: string,
  ) => any;
}
export const RegistrationStepsHandler = ({
  event,
  user,
  guest,
  coupons,
  checkedCoupon,
  plansAndOptions,
  fetchGuestData,
}: RegistrationStepsHandlerProps) => {
  const selectedPlanId = useQuery().get('planId');
  const selectedOptionId = useQuery().get('optionId');
  const queryParams = useQuery();
  const couponToSelect = useQuery().get('coupon') ?? '';
  const oneCoupon = useQuery(true).get('onecoupon') ?? '';
  const [allowedCoupons] = useState(EVENT_FULL_ALLOWED_COUPONS);
  const isMounted = useComponentDidMount();
  const filteredCoupons = useMemo(
    () => filterCoupons(coupons, allowedCoupons, checkedCoupon),
    [allowedCoupons, checkedCoupon, coupons],
  );
  const [invoicings, setInvoicings] = useState(
    mergeWithGuestAddress(guest.invoicingData ?? [], guest),
  );
  const [data, setData] = useState<GuestData>(
    getInitialGuestData(
      guest,
      invoicings,
      plansAndOptions,
      filteredCoupons,
      selectedPlanId,
      couponToSelect,
    ),
  );
  const [tab, setTab] = useState<number>(REGISTRATION_FULL_STEPS.PERSONAL_DATA);

  const selectedCoupons = useSelectedCoupons(data, filteredCoupons);
  const selectedOptions = useSelectedOptions(data, plansAndOptions);
  const selectedPlan = useCurrentPlan(data, plansAndOptions);

  const { totalPrice } = useTotalPrice({
    guest,
    plan: selectedPlan,
    options: selectedOptions,
    coupons: selectedCoupons,
  });

  const extraOptions = useExtraOptions({
    plan: selectedPlan,
    plansAndOptions,
    coupons: selectedCoupons,
  });

  const hasDelivrableOption = selectedOptions.some(
    (op) => +op.isDeliverable === 1,
  );

  const withStepOptions = extraOptions.length > 0;
  const withStepAdress = !(totalPrice <= 0 && !hasDelivrableOption);
  const withStepPayment = totalPrice > 0;
  const withOnlyStepPlan = !withStepOptions && !withStepAdress;
  const withOnlyStepPersonalData = !isEmpty(oneCoupon);

  useEffect(() => {
    const newAdresses = mergeWithGuestAddress(guest.invoicingData ?? [], guest);
    setInvoicings(newAdresses);
    setData(
      getInitialGuestData(
        guest,
        newAdresses,
        plansAndOptions,
        filteredCoupons,
        selectedPlanId,
        couponToSelect,
      ),
    );
  }, [guest, plansAndOptions]);

  useEffect(() => {
    if (!isMounted) return;

    const selectedPlan = plansAndOptions?.plans?.find(
      ({ id }) => +data.plan === +id,
    );

    const additionalOptionsIds =
      selectedPlan?.options
        .filter(({ type }) => +type === PLAN_OPTION_TYPE.ADDITIONAL)
        .map((op) => +op.option) ?? [];

    const extraOptions =
      plansAndOptions?.extraPlanOptions.map((op) => +op.id) ?? [];

    const coupons = data.coupons
      ? getSelectedCoupons(data.coupons, filteredCoupons)
      : [];

    const extra = [...additionalOptionsIds, ...extraOptions].filter(
      (optionId) => {
        // The idea is to remove coupon related options
        return plansAndOptions?.options.some(
          (op) =>
            +op.id === +optionId &&
            (+op.byCoupon === 0 ||
              (+op.byCoupon === 1 &&
                coupons.some((cp) =>
                  cp.reductions.some(
                    (rd) =>
                      +rd.id === +op.id &&
                      rd.type === COUPON_REDUCTION_TYPE.OPTION,
                  ),
                ))),
        );
      },
    );

    const newOptions =
      data.options?.filter((op) => extra.indexOf(op) !== -1) ?? [];

    if (difference(data.options, newOptions).length > 0) {
      setData((data) => ({ ...data, options: newOptions }));
    }
  }, [data.plan]);

  useEffect(() => {
    const step = +guest.step;
    let guestStep;

    switch (step) {
      case 1:
        guestStep = REGISTRATION_FULL_STEPS.PERSONAL_DATA;
        break;
      case 2: {
        guestStep = REGISTRATION_FULL_STEPS.PLAN;
        const registeringChoice: RegistredChoice | undefined = parseJson(
          guest.registeringChoice,
        );
        const registeredChoice: RegistredChoice | undefined = parseJson(
          guest.registeredChoice,
        );

        let plan = 0;
        if (registeringChoice?.plan && +registeringChoice.plan > 0) {
          plan = +registeringChoice.plan;
        } else if (registeredChoice?.plan && +registeredChoice.plan > 0) {
          plan = +registeredChoice.plan;
        }

        if (plan > 0) {
          let options: string[] = [];

          if (
            registeringChoice?.options &&
            Array.isArray(registeringChoice.options)
          ) {
            options = registeringChoice.options;
          } else if (
            registeredChoice?.options &&
            Array.isArray(registeredChoice.options)
          ) {
            options = registeredChoice.options;
          }

          if (options.length > 0) {
            guestStep = REGISTRATION_FULL_STEPS.ADDRESS;
          } else {
            guestStep = REGISTRATION_FULL_STEPS.OPTIONS;
          }
        }

        break;
      }
      case 3:
      case 4:
        guestStep = REGISTRATION_FULL_STEPS.PAYMENT;
        break;
      case 5:
      case 6:
        // If registred open change plan
        guestStep = REGISTRATION_FULL_STEPS.PLAN;
        break;
      default:
        guestStep = REGISTRATION_FULL_STEPS.PERSONAL_DATA;
    }
    if (queryParams.has('openStep')) {
      const openStep = queryParams.get('openStep');
      switch (openStep) {
        case 'options':
          guestStep = REGISTRATION_FULL_STEPS.OPTIONS;
          break;
        default:
          guestStep = REGISTRATION_FULL_STEPS.PERSONAL_DATA;
          break;
      }
    }
    setTab(getInitStep(guestStep));
  }, []);

  const getInitStep = (step: number): number => {
    switch (step) {
      case REGISTRATION_FULL_STEPS.OPTIONS:
        return withStepOptions
          ? step
          : getInitStep(REGISTRATION_FULL_STEPS.PLAN);
      case REGISTRATION_FULL_STEPS.ADDRESS:
        return withStepAdress
          ? step
          : getInitStep(REGISTRATION_FULL_STEPS.OPTIONS);
      case REGISTRATION_FULL_STEPS.PAYMENT:
        return withStepPayment
          ? step
          : getInitStep(REGISTRATION_FULL_STEPS.ADDRESS);
      default:
        return step;
    }
  };

  const getGuestData = useCallback(() => {
    if (user) {
      fetchGuestData(event.id, user.id, oneCoupon as string);
    }
  }, [event.id, fetchGuestData, user]);

  const handleChangeData = useCallback((newData: Partial<GuestData>) => {
    setData((data) => ({ ...data, ...newData }));
  }, []);

  return (
    <Onboarding
      selectedTab={tab}
      title={t('Individual registration')}
      className={cn('flex-1', s.onboarding)}
    >
      <OnboardingPage
        className={s.page}
        tab={{
          id: REGISTRATION_FULL_STEPS.PERSONAL_DATA,
          title: t('Personal data'),
          // onClick: (id) => setTab(id as number),
        }}
      >
        <StepPersonalData
          isOneClick={!isEmpty(oneCoupon)}
          event={event}
          guest={guest}
          user={user}
          goToStep={setTab}
          getGuestData={getGuestData}
          nextStep={REGISTRATION_FULL_STEPS.PLAN}
          couponToSelect={couponToSelect}
        />
      </OnboardingPage>
      {!withOnlyStepPersonalData && (
        <OnboardingPage
          className={s.page}
          tab={{
            id: REGISTRATION_FULL_STEPS.PLAN,
            title: t('Plans choice'),
            // onClick: (id) => setTab(id as number),
          }}
        >
          <StepPlan
            coupons={filteredCoupons}
            allowedCoupons={allowedCoupons}
            couponToSelect={couponToSelect}
            event={event}
            guest={guest}
            data={data}
            user={user}
            handleChangeData={handleChangeData}
            goToStep={setTab}
            previousStep={REGISTRATION_FULL_STEPS.PERSONAL_DATA}
            nextStep={
              withOnlyStepPlan // when stepOptions and stepAddress are hidden, call the save directly in StepPlan
                ? REGISTRATION_FULL_STEPS.END
                : withStepOptions
                ? REGISTRATION_FULL_STEPS.OPTIONS
                : REGISTRATION_FULL_STEPS.ADDRESS
            }
          />
        </OnboardingPage>
      )}
      {withStepOptions && !withOnlyStepPersonalData && (
        <OnboardingPage
          className={s.page}
          tab={{
            id: REGISTRATION_FULL_STEPS.OPTIONS,
            title: t('Options choice'),
            // onClick: (id) => setTab(id as number),
          }}
        >
          <StepOptions
            event={event}
            guest={guest}
            user={user}
            data={data}
            selectedCoupons={selectedCoupons}
            selectedPlan={selectedPlan}
            handleChangeData={handleChangeData}
            goToStep={setTab}
            selectedOptionId={parseInt(selectedOptionId as string)}
            previousStep={REGISTRATION_FULL_STEPS.PLAN}
            nextStep={
              !withStepAdress
                ? REGISTRATION_FULL_STEPS.END
                : REGISTRATION_FULL_STEPS.ADDRESS
            }
          />
        </OnboardingPage>
      )}
      {withStepAdress && !withOnlyStepPersonalData && (
        <OnboardingPage
          className={s.page}
          tab={{
            id: REGISTRATION_FULL_STEPS.ADDRESS,
            title: t('Billing data'),
            // onClick: (id) => setTab(id as number),
          }}
        >
          <StepAddress
            event={event}
            guest={guest}
            data={data}
            invoicingAddresses={invoicings}
            handleChangeData={handleChangeData}
            goToStep={setTab}
            selectedCoupons={selectedCoupons}
            selectedOptions={selectedOptions}
            selectedPlan={selectedPlan}
            totalPrice={totalPrice}
            previousStep={
              withStepOptions
                ? REGISTRATION_FULL_STEPS.OPTIONS
                : REGISTRATION_FULL_STEPS.PLAN
            }
          />
        </OnboardingPage>
      )}
      {withStepPayment && !withOnlyStepPersonalData && (
        <OnboardingPage
          className={s.page}
          tab={{
            id: REGISTRATION_FULL_STEPS.PAYMENT,
            title: t('Payment'),
            // onClick: (id) => setTab(id as number),
          }}
        >
          <StepPayment
            guest={guest}
            goToStep={setTab}
            getGuestData={getGuestData}
            previousStep={
              withStepAdress
                ? REGISTRATION_FULL_STEPS.ADDRESS
                : withStepOptions
                ? REGISTRATION_FULL_STEPS.OPTIONS
                : REGISTRATION_FULL_STEPS.PLAN
            }
          />
        </OnboardingPage>
      )}
    </Onboarding>
  );
};

export default RegistrationStepsHandler;

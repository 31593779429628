import ModalManager from 'components/Modal/ModalManager';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import TTPDialog from 'Common/TTPDialog';
import FlashMessage from './FlashMessage';
import Menu from 'components/Base/Menu';
import { matchPath, useLocation } from 'react-router-dom';
import { URLS } from 'router';
import { connect } from 'react-redux';
import { fetchPrivacy } from 'store/Privacy/thunks';

const Layout = ({ children, fetchPrivacy, privacyRubrics }) => {
  const location = useLocation();
  const hideMenu = !!matchPath(location.pathname, [
    URLS.webinar.webinarRegister,
    URLS.terms.root,
    URLS.modalCloser,
  ]);
  // To fix the flickering when scrolling to the bottom, a margin bottom is added in the main UA
  // element (the watch iframe wrapper). For the sake of style, the background of the off-canvas
  // should match the background color of the footer (to hide the white space caused by the added
  // margin). This logic should be implemented in UA if the same problem occur.
  const isUA = !!matchPath(location.pathname, URLS.ua);

  useEffect(() => {
    if (!privacyRubrics) {
      fetchPrivacy();
    }
  }, [privacyRubrics]);

  return (
    <div
      className="off-canvas-wrapper"
      style={{ backgroundColor: isUA ? '#3C4E64' : 'none' }}
    >
      {!hideMenu && <Menu />}
      {children}
      <FlashMessage />
      <ToastContainer hideProgressBar newestOnTop />
      <TTPDialog />
      <ModalManager />
    </div>
  );
};

const mapStateToProps = (state) => ({
  privacyRubrics: state.privacy.items,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPrivacy: (options) => dispatch(fetchPrivacy(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { memo, useEffect, useState } from 'react';

import styles from '../StepCollaboratorsData.module.scss';
import classNames from 'classnames';
import {
  areaCodes,
  userRoles,
  languages,
  Collaborator,
  getCollabFormInitialValues,
  Lng,
  roles,
} from '../services';
import { Formik, Form } from 'formik';
import { onSuccess, onError } from 'utils';
import { ReactComponent as SpinIcon } from 'assets/icons/tail-spin.svg';
import { ReactComponent as AlertTriangle } from 'assets/icons/alert-triangle.svg';
import t from 'i18n';
import { TTPInputField, TTPSelectField } from 'Common/TTPForm';
import * as Yup from 'yup';
import * as yup from 'yup';

export interface CollaboratorsProps {
  organizationId: number;
  onResetForm: () => void;
  isEdit: boolean | null;
  updateCollaborator: (
    user: number,
    organization: number,
    email: string,
    emailId: number,
    firstName: string,
    lastName: string,
    role: any,
    roleId: number,
    phone: string,
    phoneId: number,
    lng: any,
    isEdit: any,
  ) => any;
  saveCollaborators: (
    organization: number,
    email: string,
    firstName: string,
    lastName: string,
    role: any,
    phone: string,
    lng: any,
  ) => any;
  saved: boolean;
  saving: boolean;
  error?: string;
  fetchCollaborators: (organizationId: number) => void;
  data: Collaborator | null;
  findUserByEmail: (email: string) => any;
  userFetching: boolean;
  userFetched: boolean;
}

export default memo(function NewCollaboratorForm({
  organizationId,
  onResetForm,
  isEdit,
  saveCollaborators,
  updateCollaborator,
  findUserByEmail,
  saved,
  saving,
  error,
  fetchCollaborators,
  data,
  userFetching,
}: CollaboratorsProps) {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleEditCollaborator = (
    user: number,
    organization: number,
    email: string,
    emailId: number,
    firstName: string,
    lastName: string,
    role: any,
    roleId: number,
    phone: string,
    phoneId: number,
    lng: any,
    isEdit: any,
  ) => {
    return updateCollaborator(
      user,
      organization,
      email,
      emailId,
      firstName,
      lastName,
      role,
      roleId,
      phone,
      phoneId,
      lng,
      isEdit,
    )
      .then((res: any) => {
        onSuccess(res, {
          title: t('saveSuccess'),
          body:
            isEdit == null ? t('collaboratorSaved') : t('collaboratorUpdated'),
        });
        onResetForm();
        setSelectedRole(null);
        fetchCollaborators(organizationId);
      })
      .catch((err: any) => {
        setSelectedRole(role);
        onError(
          err,
          isEdit == null
            ? t('Enter his phone number ending with') +
                ' ' +
                (data?.cachedNumber ? data?.cachedNumber?.slice(-2) : '')
            : t(err),
        );
      });
  };

  const handleSubmit = (
    organization: number,
    email: string,
    firstName: string,
    lastName: string,
    role: any,
    phone: string,
    lng: any,
  ) => {
    saveCollaborators(
      organization,
      email,
      firstName,
      lastName,
      role,
      phone,
      lng,
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={getCollabFormInitialValues(data, selectedRole)}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().min(2).required(t('firstNameRequired')),
        lastName: Yup.string().min(2).required(t('lastNameRequired')),
        email: Yup.string()
          .trim()
          .email(t('invalidEmail'))
          .required(t('emailRequired'))
          .label(t('emailRequired')),
        phoneNumber: yup
          .string()
          .test('len', t('invalidPhoneNumber'), (val) => {
            const phone = val?.substr(3).trim();
            const isNum = /^\d+$/.test(phone);

            return phone?.length >= 8 && isNum;
          })
          .required(t('phoneRequired')),
        lng: yup
          .string()
          .oneOf(Lng, t('yup.invalid'))
          .required(t('languageRequired'))
          .label(t('languageRequired')),
        role: yup
          .string()
          .oneOf(roles, t('yup.invalid'))
          .required(t('roleRequired'))
          .label(t('roleRequired')),
        areaCode:
          isEdit == false || isEdit == null
            ? yup
                .string()
                .required(t('areaCodeRequired'))
                .label(t('areaCodeRequired'))
            : yup.string(),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        setSubmitting(false);
        if (isEdit || isEdit == null) {
          handleEditCollaborator(
            values.id,
            organizationId,
            values.email,
            values.emailId,
            values.firstName,
            values.lastName,
            values.role,
            values.roleId,
            values.phoneNumber.replace(/\s+/g, ''),
            values.phoneId,
            values.lng,
            isEdit,
          );
        } else {
          handleSubmit(
            organizationId,
            values.email,
            values.firstName,
            values.lastName,
            values.role,
            values.phoneNumber.replace(/\s+/g, ''),
            values.lng,
          );
        }

        setSubmitting(false);
      }}
      /*onReset={(values, { resetForm }) => {
        onResetForm();
      }}*/
    >
      {({
        isSubmitting,
        isValidating,
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
      }: any) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <div className={styles.collaborator_form}>
            <div
              className="grid-x"
              style={{
                marginBottom: errors.email && touched.email ? '0%' : '5%',
              }}
            >
              <div className="small-12 align-stretch p-r-xs">
                <TTPInputField
                  type="text"
                  autoComplete="off"
                  theme={'orangeRoyal'}
                  name="email"
                  label={t('addressMail')}
                  labelClassName={styles.weight_400}
                  placeholder={t('addressMail')}
                  customizeError={true}
                  required={true}
                  onBlur={(e) => {
                    if (validateEmail(e.currentTarget.value))
                      findUserByEmail(e.currentTarget.value);
                  }}
                  style={{
                    border:
                      errors.email && touched.email && '1px solid #FE3745',
                  }}
                />
                {errors.email && touched.email ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>{errors.email}</span>
                  </span>
                ) : null}
              </div>
            </div>

            <div
              className="grid-x"
              style={{
                marginBottom:
                  (errors.firstName && touched.firstName) ||
                  (errors.lastName && touched.lastName)
                    ? '0%'
                    : '5%',
              }}
            >
              <div className="small-6 align-stretch p-r-xs">
                <TTPInputField
                  customizeError={true}
                  type="text"
                  autoComplete="off"
                  theme={'orangeRoyal'}
                  name="firstName"
                  label={t('firstName')}
                  labelClassName={styles.weight_400}
                  placeholder={t('firstName')}
                  required={true}
                  disabled={isEdit == null}
                  style={{
                    border:
                      errors.firstName &&
                      touched.firstName &&
                      '1px solid #FE3745',
                  }}
                />
                {errors.firstName && touched.firstName ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>
                      {errors.firstName}
                    </span>
                  </span>
                ) : null}
              </div>

              <div className="small-6 align-stretch p-r-xs">
                <TTPInputField
                  customizeError={true}
                  type="text"
                  autoComplete="off"
                  theme={'orangeRoyal'}
                  name="lastName"
                  label={t('lastName')}
                  disabled={isEdit == null}
                  labelClassName={styles.weight_400}
                  placeholder={t('lastName')}
                  required={true}
                  style={{
                    border:
                      errors.lastName &&
                      touched.lastName &&
                      '1px solid #FE3745',
                  }}
                />
                {errors.lastName && touched.lastName ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>
                      {errors.lastName}
                    </span>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="grid-x">
              <div className="small-12 align-stretch p-r-xs">
                <span style={{ float: 'right', marginRight: '2%' }}>
                  {isEdit == null ? data?.cachedNumber : ''}
                </span>
              </div>
            </div>
            <div
              className="grid-x"
              style={{
                marginBottom:
                  (errors.areaCode && touched.areaCode) ||
                  (errors.phoneNumber && touched.phoneNumber)
                    ? '0%'
                    : '5%',
              }}
            >
              <div className="small-4 align-stretch p-r-xs">
                <TTPSelectField
                  customizeError={true}
                  theme={'orangeRoyal'}
                  name="areaCode"
                  label={t('areaCode')}
                  labelClassName={styles.weight_400}
                  options={areaCodes}
                  placeholder={t('areaCode')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                  onChange={(option: any) => {
                    let phone = values.phoneNumber;

                    if (phone.length > 0) {
                      if (phone.charAt(0) == '0') {
                        phone = phone.substring(1);
                      }
                      let newArea = '';
                      if (phone.includes('+')) {
                        const code = phone.substr(0, 2);
                        if (code != option.value) {
                          newArea = option.value + phone.substr(3);
                        }
                      } else {
                        newArea =
                          option.value +
                          ' ' +
                          phone.substr(phone.indexOf(' ') + 1);
                      }
                      setFieldValue('phoneNumber', newArea);
                      setFieldValue('areaCode', option.value);
                    } else {
                      setFieldValue('areaCode', option.value);
                    }
                  }}
                  style={{
                    border:
                      errors.areaCode &&
                      touched.areaCode &&
                      '1px solid #FE3745 !important',
                  }}
                />
                {errors.areaCode && touched.areaCode ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>
                      {errors.areaCode}
                    </span>
                  </span>
                ) : null}
              </div>
              <div
                className="small-8 align-stretch p-r-xs"
                style={{ marginTop: '0.7%' }}
              >
                <TTPInputField
                  customizeError={true}
                  type="text"
                  autoComplete="new-password"
                  theme={'orangeRoyal'}
                  name="phoneNumber"
                  label={
                    isEdit == null
                      ? t('Confirmation du téléphone mobile')
                      : t('phoneNumber')
                  }
                  labelClassName={styles.weight_400}
                  placeholder={
                    isEdit == null
                      ? t('Saisissez le numero de téléphone mobile ...')
                      : t('phoneNumber')
                  }
                  required={true}
                  onBlur={(e) => {
                    const number = e.currentTarget.value;
                    const areaCode = values.areaCode;
                    if (
                      areaCode.length > 0 &&
                      number.length > 0 &&
                      number.charAt(0) != '+'
                    ) {
                      if (number.charAt(0) == '0' || number.charAt(0) == ' ') {
                        setFieldValue(
                          'phoneNumber',
                          values.areaCode + number.substring(1).trim(),
                        );
                      } else {
                        setFieldValue(
                          'phoneNumber',
                          values.areaCode + number.trim(),
                        );
                      }
                    } else {
                      setFieldValue('phoneNumber', number.trim());
                    }
                  }}
                  style={{
                    border:
                      errors.phoneNumber &&
                      touched.phoneNumber &&
                      '1px solid #FE3745',
                  }}
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>
                      {errors.phoneNumber}
                    </span>
                  </span>
                ) : null}
              </div>
            </div>

            <div
              className="grid-x"
              style={{
                marginBottom:
                  (errors.role && touched.role) || (errors.lng && touched.lng)
                    ? '0%'
                    : '5%',
              }}
            >
              <div className="small-6 align-stretch p-r-xs">
                <TTPSelectField
                  customizeError={true}
                  theme={'orangeRoyal'}
                  name="role"
                  label={t('Collaborator role')}
                  labelClassName={styles.weight_400}
                  options={userRoles}
                  placeholder={t('Collaborator role')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                  style={{
                    border: errors.role && touched.role && '1px solid #FE3745',
                  }}
                />
                {errors.role && touched.role ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>{errors.role}</span>
                  </span>
                ) : null}
              </div>

              <div className="small-6 align-stretch p-r-xs">
                <TTPSelectField
                  customizeError={true}
                  theme={'orangeRoyal'}
                  name="lng"
                  label={t('lng')}
                  labelClassName={styles.weight_400}
                  options={languages}
                  placeholder={t('lng')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                  disabled={isEdit == null}
                  style={{
                    border: errors.lng && touched.lng && '1px solid #FE3745',
                  }}
                />
                {errors.lng && touched.lng ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>{errors.lng}</span>
                  </span>
                ) : null}
              </div>
            </div>
            <div className={styles.actions_block}>
              <div className="grid-x">
                <div className="small-6 align-stretch p-r-xs">
                  <button
                    className={classNames(styles.cancel_btn)}
                    type="reset"
                    onClick={() => onResetForm()}
                    disabled={isSubmitting || isValidating}
                  >
                    <span>{t('CANCEL')}</span>
                  </button>
                </div>

                <div className="small-6 align-right p-r-xs">
                  <button
                    className={classNames(styles.submit_btn)}
                    type="submit"
                    disabled={isSubmitting || isValidating}
                  >
                    {(saving || userFetching) && (
                      <div>
                        <SpinIcon className="tiny-loader" />
                      </div>
                    )}

                    <span>{isEdit ? t('update') : t('Add')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
});

const URLS = {
  homePage: [
    '/',
    '/search',
    '/community/:communityUrl/:communityId',
    '/communities',
  ],
  autoLogin: '*/autologin',
  autoLog: '*/autolog',
  community: '/community/:communityUrl/:communityId',
  search: '/search',
  event: '/event/:eventId',
  light: '/event/light/:eventId',
  webinar: {
    root: '/event/:eventId',
    home: '/event/:eventId/reception',
    landingPage: '/event/:eventId/landing',
    reception: {
      home: '/event/:eventId/reception',
      program: '/event/:eventId/reception/program',
      // speakers: '/event/:eventId/reception/speakers',
      inscription: '/event/:eventId/reception/inscription',
      articles: '/event/:eventId/reception/articles',
      registration: {
        root: '/event/:eventId/reception/registration',
        closed: '/event/:eventId/reception/registration/closed',
        step1: '/event/:eventId/reception/registration/step1',
        step2: '/event/:eventId/reception/registration/step2',
        step4: '/event/:eventId/reception/registration/step3',
        step5: '/event/:eventId/reception/registration/step4',
      },
      administration: '/event/:eventId/reception/administration',
    },
    speakers: '/event/:eventId/speakers',
    webinarLive: '/event/:eventId/live',
    webinarStage: '/event/:eventId/tv',
    webinarSessions: {
      home: '/event/:eventId/session',
      program: '/event/:eventId/session/program',
      all: '/event/:eventId/session/all',
      upcoming: '/event/:eventId/session/upcoming',
      past: '/event/:eventId/session/past',
    },
    webinarExhibitors: {
      list: '/event/:eventId/exhibitors',
      current: '/event/:eventId/exhibitors/:exhibitorId',
      manager: '/event/:eventId/exhibitors/:exhibitorId/manager',
      guests: '/event/:eventId/exhibitors/:exhibitorId/manager/guests',
      offers: '/event/:eventId/exhibitors/:exhibitorId/manager/offers',
      docs: '/event/:eventId/exhibitors/:exhibitorId/manager/docs',
      leads: '/event/:eventId/exhibitors/:exhibitorId/manager/leads',
    },
    webinarDocs: '/event/:eventId/docs',
    webinarAds: '/event/:eventId/ads',
    webinarChat: '/event/:eventId/chat',
    webinarRegister: '/event/:eventId/register',
  },
  cycle: {
    root: '/cycle/:cycleId',
    home: '/cycle/:cycleId/reception',
    reception: {
      home: '/cycle/:cycleId/reception',
      program: '/cycle/:cycleId/reception/program',
      speakers: '/cycle/:cycleId/reception/speakers',
      inscription: '/cycle/:cycleId/reception/inscription',
      registration: {
        root: '/cycle/:cycleId/reception/registration',
        closed: '/cycle/:cycleId/reception/registration/closed',
        step1: '/cycle/:cycleId/reception/registration/step1',
        step2: '/cycle/:cycleId/reception/registration/step2',
        step4: '/cycle/:cycleId/reception/registration/step3',
        step5: '/cycle/:cycleId/reception/registration/step4',
      },
    },
    events: {
      root: '/cycle/:cycleId/events',
      all: '/cycle/:cycleId/events',
      ongoing: '/cycle/:cycleId/events/ongoing',
      upcoming: '/cycle/:cycleId/events/upcoming',
      past: '/cycle/:cycleId/events/past',
    },
  },
  premiumOffer: {
    root: '/cycle/:premiumOfferId/reception/registration',
  },
  subscription: {
    root: '/subscription/:communityId',
    home: '/subscription/:communityId/reception',
    reception: {
      home: '/subscription/:communityId/reception',
      registration: {
        root: '/subscription/:communityId/reception/registration',
        closed: '/subscription/:communityId/reception/registration/closed',
        step1: '/subscription/:communityId/reception/registration/step1',
        step2: '/subscription/:communityId/reception/registration/step2',
        step4: '/subscription/:communityId/reception/registration/step3',
        step5: '/subscription/:communityId/reception/registration/step4',
      },
    },
  },
  speakers: '/speakers',
  planSelector: '/plan-selector',
  plans: '/plans',
  modalCloser: '/modal-closer',
  terms: {
    root: '/terms',
    event: '/terms/event',
  },
  mySpace: {
    root: '/my-space',
    trainings: '/my-space/trainings',
    documents: '/my-space/documents',
  },
  library: {
    root: '/library',
    trainings: '/library/trainings',
    cycles: '/library/cycles',
    seasons: '/library/seasons',
    essentials: '/library/essentials',
  },
  myReplays: '/my-replays',
  faq: '/faq',
  healthCheck: '/health-check', // amazon health check
  channels: '/channels',
  channel: '/channel/:channelId',
  //ua: '/ua', // TODO this route just for test
  watch: {
    root: '/watch',
    trainings: '/watch/trainings',
    favorites: '/watch/favorites',
    search: '/watch/search',
  },
  privacy: ['*/privacy', '*/privacy/cookies', '*/privacy/terms_of_use'],
  fff: '/fff',
  logout: '/logout',
  privacyTerms: '/:language/privacy',
};

export default URLS;

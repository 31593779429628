import axios, { CancelTokenSource, AxiosResponse } from 'axios';
import { pushSourceToken as pushSourceTokenAction } from 'store/Params/actions';
import moment from 'moment';
import _ from 'i18n';

import {
  LANGUAGES,
  MAP_LANGUAGES,
  S3_FOLDER_URL,
  S3_FOLDER_URL_PROD,
  TTP_HOME_URL,
} from 'config';
import { Dispatch } from 'redux';
import { Language } from 'store/types';
import { toast } from 'react-toastify';
import {
  capitalize,
  escapeRegExp,
  isEmpty,
  last,
  startCase,
  uniq,
  upperFirst,
} from 'lodash';
import { matchPath } from 'react-router-dom';
import { LanguageOptionsType } from 'store/Params/Language';
import { appendUrlScheme, bindLinkData, getHeight } from './app';
import { AppInfoData } from 'store/Params/AppInfo';
import { Cycle } from 'store/Cycle/types';
import { getEventNbMinutes } from './event';
import { Event } from 'store/Events/types';
import { number } from 'yup';
import { URLS } from 'router';

type Replacement = [string, string];

export const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function updateSourceToken(
  oldSourceToken: CancelTokenSource,
  newSourceToken: CancelTokenSource,
) {
  if (oldSourceToken) {
    oldSourceToken.cancel('Operation canceled by the user.');
  }
  return newSourceToken;
}

export function pushSourceToken(sourceName: string, dispatch: Dispatch) {
  const sourceToken = getSourceToken();
  dispatch(pushSourceTokenAction(sourceName, sourceToken));
  return sourceToken;
}

export function getSourceToken(): CancelTokenSource {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
}

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const getDefaultLanguage = (): string => {
  let lng = navigator.language || (navigator as any).userLanguage || '';
  lng = lng.split('-')[0];
  return ['fr', 'en', 'nl'].includes(lng) ? lng : 'en';
};

// TODO review this
const MyError = { response: { status: 700 } };
export function throwCatchedError(thrown: any) {
  if (axios.isCancel(thrown)) {
    throw MyError;
  } else {
    throw thrown;
  }
}

export const randomIntBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const redirectToHome = (appInfo: AppInfoData, returnTo = '') => {
  if (appInfo.isNextApp) {
    window.parent.postMessage(
      {
        message: 'REDIRECT_TO_HOME',
        link: returnTo,
      },
      '*',
    );

    return;
  }
  const goto = encodeURIComponent(appInfo.url + returnTo);
  const searchParam =
    appInfo.id === 'OFFFCOURSE' ? `gotoUrl=${goto}` : `goto=${goto}`;

  window.location.assign(`${TTP_HOME_URL}?${searchParam}`);
};

export function sortTags(
  tags: Array<{ nameEn: string; nameFr: string; nameNl: string }>,
  lng: string,
) {
  let sortedTags = tags;

  switch (lng) {
    case 'en':
      sortedTags = tags.sort(function (a, b) {
        return a.nameEn === b.nameEn ? 0 : +(a.nameEn > b.nameEn) || -1;
      });
      break;
    case 'fr':
      sortedTags = tags.sort(function (a, b) {
        return a.nameFr === b.nameFr ? 0 : +(a.nameFr > b.nameFr) || -1;
      });
      break;
    case 'nl':
      sortedTags = tags.sort(function (a, b) {
        return a.nameNl === b.nameNl ? 0 : +(a.nameNl > b.nameNl) || -1;
      });
      break;
    default:
  }

  return sortedTags;
}

export const getTagNameAttr = (lng: Language): string => {
  return `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
};

export const htmlDecode = (strData: string): string => {
  if (strData && typeof strData === 'string') {
    return strData.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec);
    });
  }
  return '';
};

export const unescapeHtml = (safe: string): string => {
  if (safe && typeof safe === 'string') {
    return safe
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, `"`)
      .replace(/&#039;/g, "'");
  }

  return '';
};

export const purifyString = (str: string): string => {
  return htmlDecode(unescapeHtml(str));
};

export function getTagName(tag: any, currentLanguage: any) {
  const languages = ['nameEn', 'nameFr', 'nameNl'].filter(
    (e) => e !== currentLanguage,
  );

  for (let i = 0; i < languages.length; i++) {
    const lng = languages[i];
    if (tag[lng] != null && tag[lng].trim !== '') {
      return tag[lng];
    }
  }

  return '';
}

export const getDateLabel = (date: string) => {
  const d = new Date(date);

  const result = d.toDateString().split(' ');

  const hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
  const minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();

  return (
    result[2] +
    ' ' +
    result[1] +
    ' ' +
    result[3] +
    ', at ' +
    hours +
    ':' +
    minutes
  );
};

/**
 * Convert a date from UTC to client Timezone
 *
 * @param date string
 * @param srcFormat string
 * @param destFormat string
 *
 * @return string formatted local date (in destFormat format)
 */
export function convertDateFromUTC(
  date: string,
  srcFormat: string = API_DATE_FORMAT,
  destFormat: string = API_DATE_FORMAT,
): string {
  if (!date) {
    return '';
  }

  const offsetMinutes = new Date().getTimezoneOffset();
  return moment(date, [srcFormat])
    .subtract(offsetMinutes, 'minutes')
    .format(destFormat);
}

export function addLandaSize(img: string, width = 0, height = 0): string {
  if (isEmpty(img)) {
    return '';
  }

  let result = img;
  let found = false;

  const splt = img.split('.');
  const ext = splt[splt.length - 1].toLowerCase();

  if (width > 0) {
    result += `/w${width}`;
    found = true;
  }
  if (height > 0) {
    const sep = width > 0 ? '-' : '/';
    result += `${sep}h${height}`;
    found = true;
  }
  result += found ? '-noEnlarge' : '/noEnlarge';

  return `${result}.${ext === 'pdf' ? 'jpg' : ext}`.replace(
    'https://s3.eu-west-1.amazonaws.com/tamtam',
    'https://s3.tamtam.pro',
  );
}

export function isUrl(str: string): boolean {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  return regexp.test(str);
}

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getMediaMimeTypeFromUrl(url: string): Promise<AxiosResponse> {
  return axios.head(url);
}

export const isAlreadyFetchedData = (
  numberItems: number,
  pageSize: number,
  page: number,
) => Math.ceil(numberItems / pageSize) === page;

export function isValidUrl(url: string) {
  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
}

export const onSuccess = (res?: any, message?: any) => {
  if (!message) {
    message = { body: 'operationCompleted', title: 'successfulAction' };
  }

  if (res?.value instanceof Error) {
    toast.error(_('errorOccured'), {
      autoClose: 3000,
    });
  } else {
    toast.success(_(message.body), {
      autoClose: 3000,
    });
  }
};

export const onError = (
  resp?: any,
  message?: any,
  autoClose?: boolean,
  isTranslated?: boolean,
) => {
  if (!message) {
    message = 'errorOccured';
  }

  const errorMessage = isTranslated ? message : _(message);

  if (typeof autoClose == 'boolean') {
    toast.error(errorMessage, {
      autoClose: false,
    });
  } else {
    toast.error(errorMessage);
  }
};

export const onWarning = (resp?: any, message?: any) => {
  toast.warning(_(message ?? 'warningOcurred'), {
    autoClose: 5000,
  });
};

export const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const preventDefault = (e: React.MouseEvent) => {
  e.preventDefault();
};

export const parseJson = <T = any>(value?: string | null): T | undefined => {
  if (!isEmpty(value) && value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return undefined;
    }
  }

  return undefined;
};

export const maxWord = (str: string, max: number, withDotes = true) => {
  let helper = '';
  let dotes = '';

  if (str) {
    const splt = str.split(' ');
    const lgt = splt.length;
    helper = splt.splice(0, max).join(' ');
    if (lgt > max) {
      dotes = '...';
    }
  }
  if (withDotes) {
    helper += dotes;
  }

  return helper;
};

/**
 * Truncates a long string
 *
 * @param str - string to truncate
 * @param max - max characters number
 * @param withDotes - append dots at the end (default: true)
 * @returns The truncated string
 */
export const maxChar = (str: string, max: number, withDotes = true) => {
  if (!isEmpty(str) && str.length > max) {
    const strCustom = str.slice(0, max);
    return withDotes ? `${strCustom}...` : strCustom;
  }

  return str;
};

const findWordHelper = <T>(
  array: T[],
  search: string,
  properties: Array<keyof T | ((item: T) => string)>,
): T[] => {
  if (isEmpty(array)) {
    return [];
  }

  if (isEmpty(search)) {
    return array;
  }

  try {
    return array.filter((item) =>
      properties.some((property) => {
        const value =
          typeof property == 'function'
            ? property(item)
            : String(item[property] ?? '');

        return (
          value.search(new RegExp(escapeRegExp(search.trim()), 'i')) !== -1
        );
      }),
    );
  } catch (e) {
    return [];
  }
};

export const findWord = <T>(
  array: T[],
  search: string,
  properties: Array<keyof T | ((item: T) => string)>,
  fallBackProperties?: Array<keyof T | ((item: T) => string)>,
): T[] => {
  if (isEmpty(array)) {
    return [];
  }

  if (isEmpty(search)) {
    return array;
  }

  let result = findWordHelper(array, search, properties);

  if (result.length === 0 && fallBackProperties) {
    result = findWordHelper(array, search, fallBackProperties);
  }

  return result;
};

export const formatDateFromTo = (
  startDateTime: string,
  endDateTime: string,
  language: Language = 'fr',
  dateFormat: string = 'll',
) => {
  if (isEmpty(startDateTime) && isEmpty(endDateTime)) {
    return '';
  }

  if (isEmpty(endDateTime)) {
    return moment(startDateTime).locale(language).format(dateFormat);
  }

  if (isEmpty(startDateTime)) {
    return moment(endDateTime).locale(language).format(dateFormat);
  }

  const startDate = moment(startDateTime).locale(language);
  const endDate = moment(endDateTime).locale(language);

  const isSameYear = startDate.isSame(endDate, 'years');
  const isSameDay = startDate.isSame(endDate, 'day');
  const isSameMonth = startDate.isSame(endDate, 'month');

  if (isSameYear && isSameDay) {
    return `${startDate.format(dateFormat)} ${_(
      'from_time',
    )} ${startDate.format('HH:mm')} ${_('to_time')} ${endDate.format('HH:mm')}`;
  }

  if (isSameYear && isSameMonth) {
    return `${_('from_date')} ${startDate.format('DD')} ${_(
      'to_date',
    )} ${endDate.format(dateFormat)}`;
  }

  if (isSameYear) {
    return `${_('from_date')} ${startDate.format('DD MMM')} ${_(
      'to_date',
    )} ${endDate.format(dateFormat)}`;
  }

  return `${_('from_date')} ${startDate.format(dateFormat)} ${_(
    'to_date',
  )} ${endDate.format(dateFormat)}`;
};

export const filterDataByLanguage = <T extends { languages?: string }>(
  data: T[],
  languages: Language[],
): T[] => {
  if (languages.length === 0 || uniq(languages).length === LANGUAGES.length) {
    return data;
  }

  return data.filter((item) => {
    const lngs =
      typeof item.languages === 'object'
        ? item.languages
        : parseJson(item.languages);

    if (typeof lngs !== 'object') {
      return false;
    }

    return languages.some((lng: Language) => lngs[capitalize(lng)] == 1);
  });
};

export const formatUen = (uen?: string | null) => {
  const _uen = (uen ?? '').replace(/[^a-zA-Z0-9]/, '');

  if (_uen.length !== 12) {
    return uen;
  }

  return _uen.replace(/^([a-zA-Z0-9]{2})(\d{4})(\d{3})(\d{3})$/, '$1 $2.$3.$4');
};

export const getPathFromUrl = (url: string) => {
  if (typeof url !== 'string') {
    return '';
  }

  return url.split(/[?#]/)[0];
};

export const getUrl = (url: string) => new URL(appendUrlScheme(url));

export const getMatchedRoutes = (routes: string[][], pathName: string) => {
  return routes.filter((subRoutes: string[]) => {
    return !!matchPath(pathName, subRoutes);
  });
};

export const sortDataByTime = <T extends { time: string }>(
  data: T[],
  dir: 'DESC' | 'ASC' = 'DESC',
): T[] =>
  data.sort((a, b) => {
    const dateTimeA = moment(a.time);
    const dateTimeB = moment(b.time);

    return dir === 'DESC'
      ? dateTimeA.diff(dateTimeB)
      : dateTimeB.diff(dateTimeA);
  });

export const parseJsonLanguages = (jsonLanguages?: string): Language[] => {
  const languages: Language[] = [];
  const mapLanguages = parseJson(jsonLanguages);

  if (!mapLanguages) return languages;

  for (const lng in mapLanguages) {
    if (
      mapLanguages[lng] == 1 &&
      LANGUAGES.includes(lng?.toLowerCase() as Language)
    ) {
      languages.push(lng?.toLowerCase() as Language);
    }
  }

  return languages;
};

export const replaceSpecialChars = (value: string) => {
  return (value ?? '')
    .replace(/'/g, 'SPECIAL_CHAR_TO_REPLACEu0027') // u0027 single quote code
    .replace(/"/g, 'SPECIAL_CHAR_TO_REPLACEu0022'); // u0022 double quote code
};

export const escapeSpecialChars = (value: string) => {
  return (value ?? '').replace(/SPECIAL_CHAR_TO_REPLACE/g, '\\');
};

export const formatDecimalHours = (
  decimalHours: string | number | undefined,
  withAbr: boolean = true,
) => {
  const hoursLabel = withAbr ? _('hour_abr') : ` ${_('hours')}`;
  const minutesLabel = withAbr ? 'min' : ` ${_('minutes')}`;
  const sep = withAbr ? ' ' : ` ${_('and')} `;

  if (!decimalHours || isNaN(+decimalHours)) {
    return `0${hoursLabel}`;
  }

  let hours = +decimalHours;
  let minutes = 0;

  minutes = hours % 1;
  hours = Math.floor(hours);
  minutes = Math.round(minutes * 60);

  if (minutes === 60) {
    minutes = 0;
    hours++;
  }

  if (minutes > 0 && hours > 0) {
    return `${hours}${hoursLabel}${sep}${minutes}${minutesLabel}`;
  }

  if (minutes > 0) {
    return `${minutes}${minutesLabel}`;
  }

  return `${hours}${hoursLabel}`;
};

export const getLanguagesFilter = (
  languageOptions: LanguageOptionsType,
): Language[] => {
  return (Object.keys(languageOptions) as Language[]).filter(
    (lng) => languageOptions[lng],
  );
};

export const prepareS3Url = (
  link: string | undefined,
  froceProd?: boolean,
  forceS3Folder?: string,
) => {
  if (!link || isEmpty(link)) {
    return link;
  }

  const s3FolderUrl = froceProd
    ? S3_FOLDER_URL_PROD
    : forceS3Folder ?? S3_FOLDER_URL;
  return `${s3FolderUrl}${link.replace('eventsFolder', 'events-folder')}`;
};

export const trimUrlSlashes = (url: string) => url.replace(/^\/+|\/+$/g, '');

export const prepareS3ResourceUrl = (
  baseS3Url: string,
  urlPath: string,
  appendEventsFolder: boolean = true,
) => {
  if (isUrl(urlPath)) return urlPath;
  if (isEmpty(baseS3Url) || isEmpty(urlPath)) return '';

  const base = trimUrlSlashes(baseS3Url);
  const path = trimUrlSlashes(urlPath).replace('eventsFolder', 'events-folder');
  const pathPrefix =
    appendEventsFolder && path.split('/')[0] !== 'events-folder'
      ? 'events-folder'
      : '';

  return `${base}${isEmpty(pathPrefix) ? '/' : `/${pathPrefix}/`}${path}`;
};

/**
 * Get the languages as a label string.
 * Example : "French, English" for ['fr', 'en']
 */
export const getLanguagesLabel = (
  languages: Language[],
  separator: string = ', ',
) => {
  if (!languages) return null;

  return languages
    .map((lng) => upperFirst(MAP_LANGUAGES[lng]))
    .sort()
    .join(separator);

  // return languages
  //   .sort()
  //   .reduce<string>(
  //     (label, lng) => label + separator + MAP_LANGUAGES[lng].toUpperCase(),
  //     '',
  //   );
};

export function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  );
}

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;

/**
 * Changes the overflow style property of the off-canvas-wrapper element.
 * Generally this fixes the problem of the position sticky that doesn't work if a parent/ascendent
 * has an overflow style. It is recommended to switch it back to 'hidden' on unmount to prevent any
 * undesired effect on the off-canvas wrapper.
 */
export const toggleOffCanvasOverflow = (
  value?: string,
  background?: string,
) => {
  const offCanvasElement = document.querySelector<HTMLElement>(
    '.off-canvas-wrapper',
  );

  offCanvasElement && (offCanvasElement.style.overflow = value ?? 'unset');
  offCanvasElement && (offCanvasElement.style.background = background ?? '');
};

export const getMainMenuHeight = () =>
  getHeight(document.querySelector('#mainMenu'));

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// this function capitalize the first letter and after each space
export const capFirstLetterInSentence = (sentence: string | undefined) => {
  const sentenceToLowerCase = sentence?.toLowerCase();
  return startCase(sentenceToLowerCase);
};

export const addSuffixS = (sentence: string, length: number) => {
  const sentenceToLowerCase = sentence.toLowerCase();
  return length > 1 ? sentenceToLowerCase + 's' : sentenceToLowerCase;
};

export const totalCycleTrainingHours = (cycle: Cycle) => {
  let cycleEventsNbMinutes = 0;

  cycle?.eventCycles?.forEach(({ eventsAbstract }) => {
    cycleEventsNbMinutes += getEventNbMinutes(eventsAbstract as Event);
  });

  return cycleEventsNbMinutes / 60;
};

export const isInIframe = () => window.self !== window.top;

export const calculatePlayProgressTime = (
  eventTime: number,
  playProgres?: number,
  isFullWatch?: number | boolean,
) => {
  if (isFullWatch) {
    return 100;
  }

  if (playProgres && playProgres > 0) {
    return ((playProgres / 60) * 100) / eventTime;
  }

  return 0;
};

export const playProgressTime = (
  playProgres?: number,
  eventTime?: number,
  isFullWatch?: number | boolean,
): number => {
  if (isFullWatch && eventTime) {
    return eventTime;
  }
  if (playProgres && playProgres > 0) {
    const playProgresMinute = Math.round(playProgres / 60);

    if (eventTime && playProgresMinute > eventTime) {
      return eventTime;
    }

    return playProgresMinute;
  }

  return playProgres ? Math.round(playProgres / 60) : 0;
};

export const calculateSavingsPercent = (
  monthlyPrice: number,
  yearlyPrice: number,
) => {
  const savingsPercent =
    ((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100;
  return Math.floor(savingsPercent);
};

export const getNextMonthDate = () => {
  const currentDate = new Date();
  const nextMonth = new Date(currentDate);
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  const day = nextMonth.getDate().toString().padStart(2, '0');
  const month = (nextMonth.getMonth() + 1).toString().padStart(2, '0');
  const year = nextMonth.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getNextYearDate = () => {
  const currentDate = new Date();
  const nextYear = new Date(currentDate);
  nextYear.setFullYear(nextYear.getFullYear() + 1);

  const day = nextYear.getDate().toString().padStart(2, '0');
  const month = (nextYear.getMonth() + 1).toString().padStart(2, '0');
  const year = nextYear.getFullYear();

  return `${day}/${month}/${year}`;
};

export const replaceMultipleStrings = (
  inputString: string,
  replacements: Replacement[],
) => {
  let outputString = inputString;

  for (const [search, replace] of replacements) {
    const regex = new RegExp(search, 'g');
    outputString = outputString.replace(regex, replace);
  }

  return outputString;
};

export const getPrivacyTermsUrl = (language: string) => {
  return bindLinkData(
    URLS.privacyTerms,
    [
      {
        key: ':language',
        value: language,
      },
    ],
    `rub=5`,
  );
};

export const capitalizeWords = (input?: string): string => {
  input = input?.toLowerCase();
  return (
    input?.replace(/(?:^|\s|,|-)\S/g, (match) => match.toUpperCase()) ?? ''
  );
};

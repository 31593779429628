import React, { useState } from 'react';
import styles from './GuestAddress.module.scss';
import cn from 'classnames';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import AddAddress from './AddAddress';
import { Address } from './Address';
import { Address as AddressType } from 'store/Guests/Invoicing/types';
import _ from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import { useDispatch } from 'react-redux';
import { setTTPDialogCustomData } from 'store/Params/actions';
import { DIALOG_TYPES } from 'store/Params/TTPDialog';
import { deleteAddress } from 'store/Guests/thunks';
import { GuestAddress as GuestAddressType } from 'store/Guests/types';
import { getGuestAddressFromInvoiceAddress } from 'utils';
import moment from 'moment';
interface Props {
  invoicings: AddressType[];
  billingSignature?: string;
  billingCompanyNumber?: string;
  defaultUen?: string;
  defaultOpenForm?: boolean;
  theme?: LayoutTheme;
  titleClassName?: string;
  title?: string;
  horizontalInputs?: boolean;
  onSelectAddress?: (invoice: GuestAddressType | null, reset?: boolean) => void;
  postDeleteAddress?: (invoice: GuestAddressType) => void;
  onOpenForm?: (isOpened: boolean) => void;
}

export const GuestAddress = ({
  theme,
  invoicings,
  billingSignature,
  billingCompanyNumber,
  defaultUen,
  defaultOpenForm,
  titleClassName,
  title,
  horizontalInputs,
  onSelectAddress,
  onOpenForm,
}: Props) => {
  const dispatch = useDispatch();
  const [manualAddresses, setManualAddresses] = useState<GuestAddressType[]>(
    [],
  );
  const [showForm, toggleShowForm] = useState(defaultOpenForm);
  const allAddresses = [...invoicings, ...manualAddresses];
  const showSwitcher = allAddresses.length > 0;

  const handleToggleForm = (value: boolean) => {
    toggleShowForm(value);
    onOpenForm?.(value);
  };

  const handleDelete = (address: AddressType) => {
    dispatch(
      setTTPDialogCustomData({
        approvedAsyncAction: () => {
          return dispatch(deleteAddress(address.signature));
        },
        message: _('Do you really want to delete this address?'),
        title: _('Confirm delete'),
        type: DIALOG_TYPES.DELETE,
      }),
    );
  };

  const isShowForm = showForm || allAddresses.length === 0;

  return (
    <div className={styles.guestAddress}>
      <div className={styles.header}>
        <div className={cn(styles.title, titleClassName)}>
          {title ?? _('Billing data')} :
        </div>
        {showSwitcher && (
          <span
            className={styles.navTo}
            onClick={() => {
              // onSelectAddress?.(null, showForm);
              handleToggleForm(!showForm);
            }}
          >
            <span>
              {isShowForm ? _('Existing addresses') : _('New address')}
            </span>
            <ArrowRightIcon className="m-l-xs" width="12px" fill="#6D7F92" />
          </span>
        )}
      </div>
      <div className="m-t-s">
        {isShowForm ? (
          <AddAddress
            theme={theme}
            horizontalInputs={horizontalInputs}
            defaultUen={defaultUen ?? ''}
            onSubmit={(data) => {
              const date = moment().format();
              const newAddress: GuestAddressType = {
                ...data,
                billingSignature: date,
              };

              setManualAddresses((address) => [newAddress, ...address]);
              onSelectAddress?.(newAddress);
              handleToggleForm(false);
            }}
            onCancel={() => handleToggleForm(false)}
          />
        ) : (
          [
            manualAddresses.map((address, index) => (
              <div className="m-b-s" key={`address-${index}`}>
                <Address
                  theme={theme}
                  data={{ type: 'GUEST', address }}
                  onClick={() => onSelectAddress?.(address)}
                  isSelected={billingSignature === address.billingSignature}
                />
              </div>
            )),
            invoicings.map((address, index) => (
              <div className="m-b-s" key={`${address.signature}-${index}`}>
                <Address
                  theme={theme}
                  data={{ type: 'INVOICING', address }}
                  onClick={() =>
                    onSelectAddress?.(
                      getGuestAddressFromInvoiceAddress(address),
                    )
                  }
                  isSelected={billingSignature === address.signature}
                  onDelete={() => handleDelete(address)}
                />
              </div>
            )),
          ]
        )}
      </div>
    </div>
  );
};

export default GuestAddress;

import React, { ReactElement, useEffect } from 'react';
import s from './StepPersonalData.module.scss';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { Event, EVENT_STATUS } from 'store/Events/types';
import t from 'i18n';
import { isGuestRegistered, parseJson } from 'utils';
import { User } from 'store/Auth/types';
import { isEmpty } from 'lodash';
import { ResourceData } from 'store/types';
import { onError, onSuccess } from 'utils';
import { Coupon, Guest } from 'store/Guests/types';
import PersonalDataForm from 'components/Registration/PersonalDataForm';
import { REGISTRATION_FULL_STEPS, StepProps } from '../../services';
import StepsContent from '../../../Common/StepsContent';
import { useFetchResource } from 'hooks/useFetchResource';
import { TTP_API_URL } from 'config';
import { School } from 'interfaces/School';

interface Props extends Omit<StepProps, 'previousStep'> {
  isOneClick: boolean;
  event: Event;
  guest: Guest;
  user: User;
  checkedCoupon: Coupon | null;
  couponToSelect: string | null;
  saveStep1: (
    eventId: string,
    userId: string,
    guestData: ResourceData<Guest>,
  ) => any;
  goToStep: (step: REGISTRATION_FULL_STEPS) => void;
  getGuestData: () => void;
  closeModal: () => void;
  checkCoupon: (params: {
    code: string;
    eventId: number;
    guestId: number;
  }) => any;
}

export default function StepPersonalData({
  event,
  user,
  guest,
  nextStep,
  checkedCoupon,
  couponToSelect,
  saveStep1,
  goToStep,
  getGuestData,
  closeModal,
  checkCoupon,
  isOneClick,
}: Props): ReactElement {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const registeredChoice = parseJson(guest.registeredChoice);
    const registeringChoice = parseJson(guest.registeringChoice ?? '');
    const { isRegistered } = isGuestRegistered(guest);

    if (
      event.status === EVENT_STATUS.CLOSED &&
      (isEmpty(registeredChoice) || isEmpty(registeringChoice))
    ) {
      // TODO
    }

    // TODO add forceModifPrinted add guest.printed
    if (isRegistered) {
      // TODO
    }
  }, []);

  const [isSchoolsFetching, schools] = useFetchResource<School[]>(
    `${TTP_API_URL}/event/school`,
    [],
    { sort: [{ property: 'name', dir: 'ASC' }], nolimit: true },
    [],
  );

  const handleSubmit = async (data: ResourceData<Guest>) => {
    try {
      const {
        value: { data: savedDGuest },
      } = await saveStep1(String(event.id), String(user.id), {
        ...data,
        cycleId: 0,
      });

      // Recheck coupon if guest is first created (guest.id == null)
      if (!guest?.id && !checkedCoupon) {
        if (event && savedDGuest?.id && !isEmpty(couponToSelect))
          await checkCoupon({
            code: couponToSelect ?? '',
            eventId: event.id,
            guestId: savedDGuest.id,
          });
      }

      onSuccess();
      // TODO improve this
      isOneClick ? handleCancel() : goToStep(nextStep);
    } catch (error) {
      onError(error);
    }
  };

  const handleCancel = () => {
    getGuestData();
    closeModal();
  };

  return (
    <StepsContent>
      <div className="flex-1">
        <h3 className={cn(s.title, 'p-x-l m-t-l')}>
          {t('Start by entering your personal data')}
        </h3>
        <PersonalDataForm
          data={guest}
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isMembershipRequired={+event.memberPrice !== +event.nonMemberPrice} // or isEventFull ??
          actionsClassName={cn('p-y-s p-x-l', s.personalDataActions)}
          wrapperClassName={s.personalDataWrapper}
          layout="3CELL"
          submitText={isOneClick ? t('Finish') : t('Next step')}
          hideSubmitIcon={isOneClick}
          schools={schools}
          isSchoolsFetching={isSchoolsFetching}
        />
      </div>
    </StepsContent>
  );
}

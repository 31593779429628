import PageContainer from 'components/Layout/PageContainer';
import React, { useEffect } from 'react';
import s from './PremiumHero.module.scss';
import Markdown from 'markdown-to-jsx';
import t from 'i18n';
import cn from 'classnames';
import PriceLabel from 'ui/PriceLabel';
import ActionButton from 'HomeSlides/Common/ActionButton/ActionButton';
import FeaturesList from 'Watch/WatchTools/FeaturesList/FeaturesList';
import _ from 'i18n';
import { ReactComponent as EducationIcon } from 'assets/icons/cil_education.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { ReactComponent as AwardIcon } from 'assets/icons/award.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { FetchParams, ResourceState } from 'store/Resource/types';
import { Cycle, CYCLE_STATUS } from 'store/Cycle/types';
import { Filter, SingleValueFilter } from 'services/Filter';
import { formatDateFromTo, getCyclePrice, TYPE_PREMIUM } from 'utils';
import TTPLoader from 'Common/TTPLoader';
import {
  OUR_OFFERS_OFFFCOURSE_URL,
  PREMIUM_DEFAULT_PRICES,
  PREMIUM_OFFER_OFFFCOURSE_URL,
} from 'config';
import { Language } from 'store/types';

interface Props {
  premiumOffersResource: ResourceState<Cycle>;
  fetchPremiumOffers: (params?: FetchParams) => void;
  language: Language;
}

const PremiumHero = ({
  premiumOffersResource: {
    fetching: isPremiumFetching,
    fetched: isPremiumFetched,
    items: premiumOffers,
  },
  fetchPremiumOffers,
  language,
}: Props) => {
  const premiumOffer = isPremiumFetched && premiumOffers[0];
  const endDate = formatDateFromTo(
    '',
    premiumOffers[0]?.endDateTime,
    language,
    'L',
  );
  const premiumOfferYear =
    premiumOffer && premiumOffer?.startDateTime.substr(0, 4);
  const { nonMemberPrice: cyclePrice } = premiumOffer
    ? getCyclePrice(premiumOffer)
    : {
        nonMemberPrice: {
          originalPrice: PREMIUM_DEFAULT_PRICES.ORIGINAL_PRICE,
          price: PREMIUM_DEFAULT_PRICES.PRICE,
        },
      };

  useEffect(() => {
    const filters: Filter[] = [
      new SingleValueFilter('type', 'eq', TYPE_PREMIUM),
      new SingleValueFilter('status', 'neq', CYCLE_STATUS.INACTIVE),
    ];
    fetchPremiumOffers({
      sort: [{ property: 'startDateTime', dir: 'DESC' }],
      filters,
    });
  }, []);

  if (isPremiumFetching) {
    return (
      <PageContainer className="p-t-0">
        <div className={s.ttp_loader}>
          <TTPLoader />
        </div>
      </PageContainer>
    );
  }

  return (
    <main>
      <PageContainer asFullPage className={cn(s.banner)}>
        <div className="grid-x">
          <div className="cell small-2 medium-2 large-2" />
          <div className={cn(s.titles, 'cell small-8 medium-8 large-8')}>
            <h1>
              <Markdown>
                {t('premium_offer_headline')
                  .replace(':endDate', endDate)
                  .replace(':year', premiumOfferYear)}
              </Markdown>
            </h1>
            <h2 className="m-t-s">{t('premium_offer_subhead')}</h2>
            <h6 className={cn('m-t-s', 'm-b-xs')}>
              {t('premium_offer_starred_title')}
            </h6>
            <div className="m-b-xs large-offset-4 meduim-offset-4">
              <PriceLabel
                price={cyclePrice.price}
                originalPrice={cyclePrice.originalPrice}
                separator={t('Instead of').toLowerCase()}
                background="transparent"
                isOriginalPriceLight
                stylePrice={{
                  fontSize: 32,
                  color: '#fff',
                  paddingLeft: 0,
                }}
                styleSeparator={{
                  fontSize: 22,
                  color: '#fff',
                  padding: '0 0.3rem 0 0.2rem',
                }}
                styleOriginalPrice={{
                  fontSize: 26,
                  color: '#fff',
                }}
              />
            </div>
            <ActionButton
              isExternal
              theme="redOrange"
              name={t('subscribe_via_offfcourse')}
              linkStyle={{
                height: '50px',
              }}
              style={{ display: 'flex', justifyContent: 'center' }}
              link={
                premiumOffer
                  ? PREMIUM_OFFER_OFFFCOURSE_URL + premiumOffer.id
                  : PREMIUM_OFFER_OFFFCOURSE_URL + '56'
              }
              isPathName={true}
              target="_blank"
              rel="noopener noreferrer"
            />
            <div>
              <a
                href={OUR_OFFERS_OFFFCOURSE_URL}
                className={s.discover}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('discover_our_offers')}
              </a>
            </div>
          </div>
          <div className="cell small-2 medium-2 large-2" />
        </div>
        <div className={s.svg}>
          <FeaturesList
            items={[
              {
                label: _('all_offfcourse_trainings'),
                icon: () => (
                  <EducationIcon width="28" height="28" fill="#FB1E5B" />
                ),
              },
              {
                label: _('access_live_and_replay'),
                icon: () => (
                  <VideoIcon
                    width="28"
                    height="28"
                    fill="#FB1E5B"
                    stroke="#FB1E5B"
                  />
                ),
              },
              {
                label: _('share_your_account'),
                icon: () => <UsersIcon width="28" height="28" fill="#FB1E5B" />,
              },
              {
                label: _('certification_included'),
                icon: () => <AwardIcon width="28" height="28" fill="#FB1E5B" />,
              },
              {
                label: _('cancel_anytime'),
                icon: () => (
                  <CancelIcon width="28" height="28" fill="#FB1E5B" />
                ),
              },
            ]}
          />
        </div>
      </PageContainer>
    </main>
  );
};

export default PremiumHero;

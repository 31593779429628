import axios from 'axios';
import { TTP_API_URL } from 'config';
import { escapeSpecialChars } from '../../utils';
import { hydrateFormData } from '../services';

export const collaboratorFields = ['*', 'role', 'phone', 'email'];

export const getEmployeePack = ({
  token,
  organizationId,
  eventId,
}: {
  token: string;
  organizationId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/employees-pack-inscription`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      eventId: eventId,
    },
  });
};

export const getCollaborators = ({
  token,
  filters = [],
  organizationId,
}: {
  token: string;
  filters?: any[];
  organizationId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/user`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      limit: 1000,
      organization_id: organizationId,
      ignorePermissionFilter: true,
      workspace: 'ua',
      filter: escapeSpecialChars(JSON.stringify(filters)),
      fields: collaboratorFields.join(','),
      sort: JSON.stringify([
        {
          property: 'createdAt',
          dir: 'desc',
        },
      ]),
    },
  });
};

export const saveCollaborators = ({
  token,
  organization,
  email,
  firstName,
  lastName,
  role,
  phone,
  lng,
}: {
  token: string;
  organization: number;
  email: string;
  firstName: string;
  lastName: string;
  role: any;
  phone: string;
  lng: any;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/user`;

  const emailParam: { [key: number]: any } = {};

  emailParam[0] = {
    email: email,
  };

  const roleParam: { [key: number]: any } = {};

  roleParam[0] = {
    type: role,
    typeStatus: '',
  };

  const phoneParam: { [key: number]: any } = {};

  phoneParam[0] = { number: phone };

  const data = {
    isEmployee: true,
    isSecure: 1,
    email: emailParam,
    firstName: firstName,
    lastName: lastName,
    phone: phoneParam,
    language: lng,
    role: roleParam,
    organization_id: organization,
  };
  const formData = new FormData();
  formData.append('access_token', token);

  hydrateFormData(formData, data);
  return axios.post(requestUrl, formData);
};

export const updateCollaborator = ({
  token,
  user,
  organization,
  email,
  emailId,
  firstName,
  lastName,
  role,
  roleId,
  phone,
  phoneId,
  lng,
  isEdit,
}: {
  token: string;
  user: number;
  organization: number;
  email: string;
  emailId: number;
  firstName: string;
  lastName: string;
  role: any;
  roleId: number;
  phone: string;
  phoneId: number;
  lng: any;
  isEdit?: any;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/user`;

  const emailParam: { [key: number]: any } = {};

  emailParam[0] = {
    email: email,
    id: emailId,
  };

  const roleParam: { [key: number]: any } = {};

  roleParam[0] = {
    type: role,
    id: roleId,
    typeStatus: '',
  };

  const phoneParam: { [key: number]: any } = {};

  phoneParam[0] = {
    number: isEdit == null ? phone.replace(/\s/g, '') : phone,
    id: phoneId,
  };

  const data = {
    id: user,
    isEmployee: true,
    isSecure: 1,
    email: emailParam,
    firstName: firstName,
    lastName: lastName,
    phone: phoneParam,
    language: lng,
    role: roleParam,
    organization_id: organization,
  };
  const formData = new FormData();
  formData.append('access_token', token);
  isEdit == null && formData.append('validatePhone', 'yes');
  hydrateFormData(formData, data);
  return axios.post(requestUrl, formData);
};

export const deleteCollaborator = ({
  token,
  userId,
  organization,
  isSimple,
  email,
  oldEmail,
}: {
  token: string;
  userId: number;
  organization: number;
  isSimple: boolean;
  email?: string;
  oldEmail: string;
}) => {
  const formData = new FormData();
  formData.append('access_token', token);

  if (isSimple) {
    const requestUrl = `${TTP_API_URL}/organization/user/detach-user-associations/${userId}`;

    formData.append('id', userId.toString());
    formData.append('organization_id', organization.toString());

    return axios.post(requestUrl, formData);
  } else {
    const requestUrl = `${TTP_API_URL}/organization/user/detach-and-update-email`;

    formData.append('userId', userId.toString());
    formData.append('organizationId', organization.toString());
    formData.append('newEmail', email ? email : '');
    formData.append('oldEmail', oldEmail ? oldEmail : '');

    return axios.post(requestUrl, formData);
  }
};

export const uploadCollaborators = ({
  token,
  data,
  organization,
  importAttachment,
}: {
  token: string;
  data: any;
  organization: number;
  importAttachment: any;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/users/import?access_token=${token}&organization_id=${organization}`;

  const formData = new FormData();

  formData.append('file', importAttachment);

  return axios.post(requestUrl, formData);
};

export const getCollaboratorByEmail = ({
  token,
  filters = [],
  email,
}: {
  token: string;
  filters?: any[];
  email: string;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/user/getUserByEmail`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      email: email,
    },
  });
};

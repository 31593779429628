import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, useHistory, matchPath } from 'react-router-dom';
import {
  selectNavCommunity,
  selectUserCommunities,
  selectToken,
  selectUserPremiumStatus,
  selectUserPremiumShared,
} from 'store/Auth';
import {
  IS_IE,
  TTP_EVENT_URL,
  TTP_EMAILING_URL,
  TTP_BLOG_URL,
  TTP_LOGO_BLOG_URL,
  FFF_ID,
} from 'config';
import _ from 'i18n';
import GoBackHeader from 'components/Common/GoBackHeader';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import { USER_ROLE } from 'store/Auth/types';
import classNames from 'classnames';
import { getHeight, isInIframe, logout } from 'utils';
import {
  getNotifData,
  getSpaceMaintainerClass,
  NOTIFS_LIMIT,
} from './services';
import { NoIE } from 'components/NoIE/NoIE';
import { Header } from 'tamtam-components';
import { TTP_HOME_URL, APP_ENV } from 'config';
import { setLanguage } from 'store/Params/Language/actions';
import { setShowFaq } from 'store/Params/Faq/actions';
import { setAuth, setNavCommunity, setAuthLoggedAs } from 'store/Auth/actions';
import { fetchEventAuthorization } from 'store/Auth/thunks';
import { fetchNotifs } from 'store/Notifs/actions';
import SubMenu from './SubMenu';
import { isEmpty } from 'lodash';
import NotificationBar from 'components/Common/NotificationBar/NotificationBar';
import {
  setShowNotificationBar,
  setNotificationBarData,
  resetNotificationBar,
} from 'store/Params/NotificationBar/actions';
import { isUserPremium, isUserPremiumPending } from 'utils';
import { resetPopupCounter } from 'store/Params/UpComingEventPopup/actions';
import { selectAppInfo } from 'store/Params/selectors';
import { URLS } from 'router';
import WatchSubMenu from './SubMenu/WatchSubMenu';
import WatchSubMenuMobile from './SubMenu/WatchSubMenuMobile';
import useResponsive from 'hooks/useResponsive';
import { resetChannelInfo } from 'store/Params/ChannelInfo/actions';
import { usePremiumSharedStatus } from 'hooks/usePremiumSharedStatus';
import { SUBSCRIPTION_FREQUENCY } from 'store/Guests/types';

export const Menu = memo(
  ({
    appInfo,
    loggedAs,
    navCommunity,
    communities,
    currentNavPage,
    user,
    token,
    lng,
    goBack,
    goBack: { showGoBack, customData, route },
    notificationBar: {
      isDisplayed: isNotifBarShown,
      data: notifBarData,
      route: notifBarRoute,
    },
    premiumOffersResource: { items: premiumOffers },
    location,
    subMenuDirection,
    notifs,
    event,
    authorizationFetched,
    authorizationFetching,
    showFaq,
    setLanguage,
    setAuth,
    fetchNotifs,
    setNavCommunity,
    setAuthLoggedAs,
    fetchEventAuthorization,
    setShowFaq,
    setShowNotificationBar,
    userPremiumStatus,
    userPremiumShared,
    setNotificationBarData,
    resetNotificationBar,
    isPremiumGuestFetching,
    resetPopupCounter,
    privacyRubrics,
    resetChannelInfo,
    premiumGuest,
  }) => {
    const [showNoIE, setShowNoIE] = useState(IS_IE);
    const [selectedOrg, setSelectedOrg] = useState();
    const history = useHistory();
    const { isMobile } = useResponsive();
    const { isShared, isFetching } = usePremiumSharedStatus();
    const headerRef = React.createRef();
    const topBarRef = React.createRef();
    const SubMenuRef = React.createRef();
    const goBackRef = React.createRef();
    const noIERef = React.createRef();
    const notifBarRef = React.createRef();
    const isWindowInIframe = isInIframe();
    const isNextApp = appInfo.isNextApp;
    // const isUA = !!matchPath(location.pathname, URLS.ua); TODO: just for test
    //const isShared = userPremiumShared;
    //const hideSubMenu = isUA || (isWindowInIframe && !isShared);
    const hideWatchSubmenu = !isWindowInIframe || !isShared || isNextApp;
    const hideSubMenu = (isWindowInIframe && !isShared) || isNextApp;
    const isAdmin = loggedAs === USER_ROLE.ROLE_ADMIN;
    const isFFF = navCommunity?.id === FFF_ID;
    const isOffcourse = appInfo.id === 'OFFFCOURSE';
    const isRegisteredToPremium = isUserPremium(userPremiumStatus);
    const hideHeaderForUa =
      !!matchPath(location.pathname, URLS.watch.root) || isNextApp;
    const isPremiumSubscriptionPending = isUserPremiumPending(
      userPremiumStatus,
    );

    useEffect(() => {
      fetchNotifs({ limit: NOTIFS_LIMIT });
      if (user && !authorizationFetched && !authorizationFetching) {
        fetchEventAuthorization();
      }
    }, []);

    useEffect(() => {
      resetPopupCounter();
      resetChannelInfo();
    }, []);

    useEffect(() => {
      if (!isNextApp) {
        resetNotificationBar();

        if (
          user &&
          (isFFF || !navCommunity) &&
          !isPremiumGuestFetching &&
          (isRegisteredToPremium || isPremiumSubscriptionPending)
        ) {
          const notifData = getNotifData(premiumGuest, setShowNotificationBar);

          if (notifData) {
            setNotificationBarData(notifData);
          }
        }
      }
    }, [
      user,
      navCommunity,
      isFFF,
      isPremiumSubscriptionPending,
      isRegisteredToPremium,
      setShowNotificationBar,
      setNotificationBarData,
      resetNotificationBar,
      isPremiumGuestFetching,
      userPremiumStatus,
      lng,
    ]);

    useEffect(() => {
      if (navCommunity) {
        setSelectedOrg(navCommunity);
      }
    }, [navCommunity]);

    const handleScrollPage = useCallback(() => {
      const headerElement = headerRef?.current;
      const topBarElement = topBarRef?.current;
      const subMenuElement = SubMenuRef?.current;
      const goBackElement = goBackRef?.current;
      const noIEElement = noIERef?.current;
      const notifBarElement = notifBarRef?.current;

      const topBarHeight = getHeight(topBarElement);
      const subMenuHeight = getHeight(subMenuElement);
      const noIEHeight = getHeight(noIEElement);
      // const goBackHeight = getHeight(goBackElement);

      if (goBackElement) {
        // resetSubMenuPosition
        if (subMenuElement) {
          subMenuElement.classList.remove('sticky');
          if (subMenuDirection === 'VERTICAL') {
            const offsetTop = topBarHeight + (showNoIE ? noIEHeight : 0);
            const verticalSubMenuTop =
              offsetTop > window.scrollY ? offsetTop - window.scrollY : 0;
            subMenuElement.style.height = `calc(100vh - ${verticalSubMenuTop}px)`;
            subMenuElement.style.width = 'auto';
          } else {
            subMenuElement.style.height = 'auto';
          }
        }
        if (notifBarElement) {
          notifBarElement.classList.remove('sticky');
          notifBarElement.classList.add('static');
        }

        const offsetTop =
          subMenuDirection === 'VERTICAL'
            ? topBarHeight + (showNoIE ? noIEHeight : 0)
            : topBarHeight + subMenuHeight + (showNoIE ? noIEHeight : 0);
        const subMenuClassName =
          subMenuDirection === 'VERTICAL' ? 'fixed' : 'visibility-hidden';

        goBackElement.classList.toggle('sticky', window.scrollY > offsetTop);
        headerElement.classList.remove('sticky');

        if (subMenuElement) {
          subMenuElement.classList.toggle(
            subMenuClassName,
            window.scrollY > offsetTop,
          );
        }
      } else if (headerElement) {
        if (!hideHeaderForUa) {
          const offsetTop = topBarHeight + (showNoIE ? noIEHeight : 0);

          if (subMenuElement) {
            subMenuElement.classList.toggle(
              'sticky',
              window.scrollY > offsetTop,
            );
            if (subMenuDirection === 'HORIZONTAL') {
              subMenuElement.style.height = 'auto';
              subMenuElement.style.width = '100%';

              if (notifBarElement) {
                notifBarElement.classList.remove('sticky');
                notifBarElement.classList.toggle(
                  'static',
                  window.scrollY < offsetTop,
                );
                if (window.scrollY > offsetTop) {
                  notifBarElement.style.top = `${subMenuHeight}px`;
                  notifBarElement.style.position = 'fixed';
                  notifBarElement.style.width = '100%';
                }
              }
            } else {
              const verticalSubMenuTop =
                offsetTop > window.scrollY ? offsetTop - window.scrollY : 0;
              subMenuElement.style.height = `calc(100vh - ${verticalSubMenuTop}px)`;
              subMenuElement.style.width = 'auto';

              if (notifBarElement) {
                notifBarElement.classList.toggle(
                  'sticky',
                  window.scrollY > offsetTop,
                );
                notifBarElement.classList.toggle(
                  'static',
                  window.scrollY < offsetTop,
                );
                notifBarElement.style.width = '100%';
              }
            }
          }
        } else {
          headerElement.classList.toggle('sticky', window.scrollY > 0);
          headerElement.style.width = '100%';
        }
      }
    }, [SubMenuRef, goBackRef, headerRef, subMenuDirection, topBarRef]);

    useEffect(() => {
      window.addEventListener('scroll', handleScrollPage);
      return () => {
        window.removeEventListener('scroll', handleScrollPage);
      };
    }, [handleScrollPage]);

    useEffect(() => {
      handleScrollPage();
    }, [subMenuDirection]);

    moment.locale(lng === 'en' ? 'en-gb' : lng);

    const spaceMaintainerClass = getSpaceMaintainerClass(
      subMenuDirection,
      hideSubMenu,
      showNoIE,
      goBack,
      location.pathname,
    );

    const handleSeeAllCommunities = () => {
      history.push('/');
      setAuthLoggedAs(user ? USER_ROLE.ROLE_USER : USER_ROLE.ROLE_GUEST);
      setNavCommunity(null);
    };

    const App = {
      appName: 'Event', // Should be 'Event' in order for notifs to work properly
      appLogoUrl: appInfo.logo?.url,
      appUrl: appInfo.url,
      homeUrl: TTP_HOME_URL,
      currentEvent: event?.id,
      isPrivateBlog: true, // NOTE: used in our case to hide appName beside Logo (should be true)
    };

    const rightIcons = {
      ebox: {
        activated: true,
        icon: 'Ebox',
        url: `${TTP_EMAILING_URL}/e_box`,
      },
      notifs: {
        activated: true,
        icon: 'Notifs',
      },
      apps: {
        activated: false,
      },
      backoffice: {
        label: 'Back office',
        activated: isAdmin,
        icon: 'Settings',
        url: TTP_EVENT_URL,
      },
      home: {
        activated: false,
      },
      profile: {
        activated: false,
        url: `${TTP_HOME_URL}/landing`,
      },
      faq: {
        activated: !isEmpty(event),
        icon: 'Help',
      },
      search: {
        activated: false,
      },
      buttonLink: {
        activated: true,
        label: 'Blog',
        url: TTP_BLOG_URL,
        icon: TTP_LOGO_BLOG_URL,
      },
    };

    const handleLogout = async (e) => {
      e.preventDefault();
      await setAuth(null);
      logout(appInfo, false, '/', isOffcourse);
    };

    const renderHeader = () => {
      const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

      const policy = [];
      const cookies = [];

      const privacyRubs = [];
      if (privacyRubrics && privacyRubrics.length > 0) {
        if (
          privacyRubrics.filter((el) => el.termsOfUse).length > 0 &&
          privacyRubrics
            .filter((el) => el.termsOfUse)[0]
            .versions.filter((ver) => ver.name === 'OFFFCOURSE').length > 0
        ) {
          policy.push({
            label: _('terms_of_use'),
            url: '/privacy/terms_of_use',
          });
        }

        privacyRubrics.forEach((el) => {
          if (el.isCookie) {
            if (
              el.versions &&
              el.showInFooter &&
              el.versions[0].status === 'PUBLISHED'
            ) {
              cookies.push({
                label:
                  el.versions[0][titleAttr].length > 25
                    ? el.versions[0][titleAttr].substring(0, 25) + '...'
                    : el.versions[0][titleAttr],
                url: `/privacy/cookies?rub=${el.id}`,
              });
            } else if (!el.versions) {
              cookies.push({
                label: _('manage_cookies'),
                url: `/privacy/cookies`,
              });
            }
          }
          if (
            el.showInFooter &&
            el.versions[0].status === 'PUBLISHED' &&
            !el.isCookie &&
            !el.termsOfUse
          )
            privacyRubs.push({
              label:
                el.versions[0][titleAttr].length > 30
                  ? el.versions[0][titleAttr].substring(0, 30) + '...'
                  : el.versions[0][titleAttr],
              url: `/privacy?rub=${el.id}`,
            });
        });
      }

      if (!user || !user.id) {
        const currentUrl = `${appInfo.url}${history.location.pathname}${history.location.search}`;

        return (
          // This is just a hack to fix the missing padding-right.
          // Contact the team for further adaptation :)
          <div ref={topBarRef} className="ttp_header_padding_fix">
            <Header
              env={APP_ENV}
              app={App}
              auth={{ navCommunity: null, user: null, token }}
              lng={lng}
              onLanguageChange={(langue) => setLanguage(langue)}
              gotoUrl={currentUrl}
            />
          </div>
        );
      }

      return (
        // The className 'ttp_header_avatar_fix' is just a hack to fix miss-centered avatar.
        // Contact the team for further adaptation :)
        <div ref={topBarRef} id="ttp-header" className="ttp_header_avatar_fix">
          <Header
            env={APP_ENV}
            app={App}
            notifications={notifs}
            auth={{
              navCommunity,
              user: {
                ...user,
                communities:
                  appInfo.id === 'OFFFCOURSE'
                    ? []
                    : communities.length
                    ? communities
                    : selectedOrg
                    ? [selectedOrg]
                    : [],
                type: loggedAs,
              },
              token,
            }}
            lng={lng}
            settings={[]}
            rightIcons={rightIcons}
            onLanguageChange={(langue) => setLanguage(langue)}
            onLogoutClick={(e) => handleLogout(e)}
            onSelectAllCommunities={handleSeeAllCommunities}
            onFAQLoad={() => setShowFaq(window.showFAQ)}
            firstList={privacyRubs.length > 0 ? privacyRubs : null}
            secondList={cookies.length > 0 ? cookies : null}
            thirdList={policy.length > 0 ? policy : null}
            navigateTo={(url) => {
              window.location.href = '/' + lng + url;
            }}
          />
        </div>
      );
    };

    if (!hideWatchSubmenu) {
      if (isFetching) return null;

      return isMobile ? (
        <div>
          <WatchSubMenuMobile />
        </div>
      ) : (
        <div ref={headerRef} style={{ zIndex: 999 }}>
          <WatchSubMenu innerRef={SubMenuRef} />
        </div>
      );
    }

    if (appInfo.withoutHeader) return null;

    return (
      <div
        id={!hideHeaderForUa ? 'headerSpaceMaintainer' : ''}
        className={
          !hideHeaderForUa
            ? classNames('space-maintainer', spaceMaintainerClass)
            : ''
        }
      >
        <div
          ref={headerRef}
          id="mainMenu"
          className={classNames(
            currentNavPage === 'HOME_PAGE' ? 'home-page' : '',
          )}
          style={{ zIndex: 22 }}
        >
          <NoIE
            visible={showNoIE}
            handleClose={() => setShowNoIE(!showNoIE)}
            innerRef={noIERef}
          />
          {!hideHeaderForUa && renderHeader()}
          {!hideSubMenu && <SubMenu innerRef={SubMenuRef} />}
          <Route path={notifBarRoute?.path} exact={notifBarRoute?.exact}>
            {isNotifBarShown && (
              <NotificationBar data={notifBarData} innerRef={notifBarRef} />
            )}
          </Route>
          <Route path={route.path} exact={route.exact}>
            {showGoBack && (
              <GoBackHeader data={customData} innerRef={goBackRef} />
            )}
          </Route>
        </div>
      </div>
    );
  },
);

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loggedAs: state.auth.loggedAs,
  navCommunity: selectNavCommunity(state),
  communities: selectUserCommunities(state),
  token: selectToken(state),
  notifs: state.notifs.items,
  lng: state.params.lng,
  goBack: state.params.goBack,
  notificationBar: state.params.notificationBar,
  subMenuDirection: state.params.subMenuDirection,
  authorizationFetched: state.auth.authorizationFetched,
  authorizationFetching: state.auth.authorizationFetching,
  event: state.event.event.items[0],
  userPremiumStatus: selectUserPremiumStatus(state),
  userPremiumShared: selectUserPremiumShared(state),
  isPremiumGuestFetching: state.event.guests.premiumGuestFetching,
  premiumOffersResource: state.cycle.premiumOffers,
  appInfo: selectAppInfo(state),
  privacyRubrics: state.privacy.items,
  premiumGuest: state.event.guests.premiumGuest,
});

const mapDispatchToProps = {
  setLanguage,
  setAuth,
  fetchNotifs,
  setNavCommunity,
  setAuthLoggedAs,
  fetchEventAuthorization,
  setShowFaq,
  setShowNotificationBar,
  setNotificationBarData,
  resetNotificationBar,
  resetPopupCounter,
  resetChannelInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));

import React, { useState, useRef, useCallback, useEffect } from 'react';
import styles from './EventLayout1.module.scss';
import {
  Event,
  EVENT_REPLAY_STATUS,
  EVENT_TYPES,
  EVENT_CERTIFICATE_STATUS,
} from 'store/Events/types';
import { Language } from 'store/types';
import {
  bindWebinarLink,
  formatDateFromTo,
  getByLanguage,
  getCroppedImageUrl,
  isEventLive,
  isEventPast,
  isEventRegistrationOpen,
  isFreeEvent,
  stopPropagation,
  isEventReplayable,
  getEventRegisterLink,
  getSlotReplayUrl,
  isReplayExpiredForUser,
  getEventNbMinutes,
  isEventUpcoming,
  capFirstLetterInSentence,
  isUserPremium,
  formatDateEndOfReplay,
  isWebinarEvent,
  parseJson,
  prepareS3ResourceUrl,
  calculatePlayProgressTime,
  playProgressTime,
  onError,
  filterSpeakersForSlots,
} from 'utils';
import classNames from 'classnames';
import { S3_FOLDER_URL, TTP_EVENT_URL } from 'config';
import LiveIcon from 'components/ui/LiveIcon';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/alert-circle.svg';
import { ReactComponent as AwardIcon } from 'assets/icons/award.svg';
import { ReactComponent as IconSettings } from 'assets/icons/settings-full.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as HybridIcon } from 'assets/icons/hybrid.svg';
import { ReactComponent as CycleIcon } from 'assets/icons/cycle-circle.svg';
import { ReactComponent as CycleDescriptionIcon } from 'assets/icons/cycle-description.svg';
import { ReactComponent as SeasonIcon } from 'assets/icons/season.svg';
import { ReactComponent as SeasonDescriptionIcon } from 'assets/icons/season-description.svg';
import { ReactComponent as ReviewIcon } from 'assets/icons/review.svg';
import { ReactComponent as ResumeIcon } from 'assets/icons/resume-icon.svg';
import { ReactComponent as TrendingGreenIcon } from 'assets/icons/trending-green.svg';
import { ReactComponent as TrendingRedIcon } from 'assets/icons/trending-red.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import ClipLoader from 'react-spinners/ClipLoader';

import { Link } from 'react-router-dom';
import t from 'i18n';
import { URLS } from 'router';
import {
  CHAT_TAB,
  PROGRAM_TAB,
  REGISTRATION_TAB,
} from 'components/Webinar/Tools/services';
import { isEmpty } from 'lodash';
import { PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import PremiumFlag from 'components/ui/PremiumFlag';
import Shave from 'components/Common/Shave';
import Markdown from 'markdown-to-jsx';
import SpeakerSlide from 'components/Common/SpeakerSlide';
import EventLayoutHover from './EventLayoutHover';
import TagsForm from './TagsForm';
import ProgressBar from 'components/ui/ProgressBar';

interface Options {
  showMainAction: boolean;
  showDetails: boolean;
  showBackOffice: boolean;
  showReplayInfo?: boolean;
  showPrice?: boolean;
  openOnNewPage?: boolean;
}

export interface EventProps {
  event: Event;
  price?: React.ReactText;
  language: Language;
  isActive?: boolean;
  isMember?: boolean;
  isAdmin?: boolean;
  hasCycle?: boolean;
  options?: Options;
  codeCoupon?: string | null;
  onClick?: () => void;
  isCycle?: boolean;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  dateEndOfReplay?: string;
  approvedBy?: string;
  multiDateIndex?: number;
  loggedAsAdmin: boolean;
  userId: number | string;
  registerPremiumToEvent: (
    eventId: number | string,
    userId: number | string,
  ) => any;
  handleEventRedirect?: (event: Event, link: string) => void;
}

export const EventLayout1 = ({
  isActive,
  isAdmin,
  isMember,
  event,
  price,
  language,
  hasCycle,
  options,
  codeCoupon,
  onClick,
  isCycle,
  userPremiumStatus,
  dateEndOfReplay,
  approvedBy,
  multiDateIndex,
  loggedAsAdmin,
  userId,
  registerPremiumToEvent,
  handleEventRedirect,
}: EventProps) => {
  const {
    startDateTime,
    endDateTime,
    memberPrice,
    nonMemberPrice,
    accreditationHours,
  } = event;
  const name = getByLanguage(event, 'name', language) ?? '';
  const label = getByLanguage(event, 'label', language, true);
  const urlBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  const banner = getCroppedImageUrl(urlBanner, undefined, 280);
  const bannerImgUrl = !isEmpty(banner)
    ? prepareS3ResourceUrl(S3_FOLDER_URL, banner)
    : '/img/event-banner-fallback-light.png';
  const place = getByLanguage(event, 'place', language);
  const eventCycles = event?.eventCycles?.filter((eventCycle) => {
    if (!eventCycle.isCyclePremium) return eventCycle;
  });
  const isEventInSeason = eventCycles?.some((cycle) => cycle.isCycleSeason);
  const isEventInCycle = eventCycles?.some((cycle) => !cycle.isCycleSeason);
  const [showCyclesIcons, setShowCyclesIcons] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showAddTags, setShowAddTags] = useState(false);
  isAdmin = loggedAsAdmin;
  const eventDateEndOfReplay = formatDateEndOfReplay(
    event.dateEndOfReplay,
    event.endDateTime,
  );
  const isFree = isFreeEvent(event as Event);
  const dateHelper = formatDateFromTo(
    startDateTime ?? '',
    endDateTime ?? '',
    language,
  );
  const isExpired = isEventPast(event as Event);
  const isUpcomming = isEventUpcoming(event as Event);
  const isReplayable = isEventReplayable(event as Event);
  const isLive = isEventLive(event as Event);
  const isWebinar = isWebinarEvent(event as Event);
  const isEventFull = +(event.type ?? 0) == EVENT_TYPES.EVENT_FULL;
  const nbHours = String(accreditationHours ?? 0);
  const nbMinutes = getEventNbMinutes(event);
  const linkBlanckProps = options?.openOnNewPage
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};
  const cycleIconStyle = {
    top: '5px',
    fontSize: '10.4px',
    width: 'max-content',
    left: '1px',
  };
  const seasonIconStyle = {
    ...cycleIconStyle,
    left: '5px',
  };

  const isRegistered = isUserPremium(userPremiumStatus);
  const [registeringPremium, setRegisteringPremium] = useState(false);

  const watchedTime = event?.playProgress ?? 0;
  const eventTime = getEventNbMinutes(event);
  const isFullWatch = event?.fullWatch ?? 0;
  const playProgress = playProgressTime(
    event?.playProgress,
    eventTime,
    isFullWatch,
  );
  const EventPlayProgress = calculatePlayProgressTime(
    eventTime,
    event?.playProgress,
    isFullWatch,
  );
  const certificateStatus = event?.certificateStatus ?? 0;
  isActive =
    isActive ||
    event['user-registered'] ||
    (isRegistered && event.isIncludedPremium == 1);

  const mainRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (mainRef.current && !mainRef.current.contains(target)) {
      setHovered(false);
      setShowAddTags(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRegisterPremium = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      setRegisteringPremium(true);
      registerPremiumToEvent(event.id, userId)
        .finally(() => {
          setRegisteringPremium(false);
        })
        .then(() => {
          event['user-registered'] = true;
          if (+event.slotsCount == 1 && event.slotReplayUrls) {
            const replayLink = getSlotReplayUrl(event.slotReplayUrls, language);
            if (replayLink) {
              window.open(replayLink, '_blank', 'noreferrer');
            }
          }
        })
        .catch((e: any) => onError());
    },
    [registeringPremium, userId, event, registerPremiumToEvent, onError],
  );

  const renderPlayProgressFlag = () => {
    if (isFullWatch) {
      return (
        <div className={classNames(styles.svgStyle)}>
          <TrendingGreenIcon />
          <div className={styles.seen}>
            <div>
              <CheckMarkIcon
                width={10}
                height={10}
                fill="#fff"
                className={classNames('m-r-xxs')}
              />{' '}
              {t('Seen')}
            </div>
          </div>
        </div>
      );
    }

    if (watchedTime > 0) {
      const timeRemained =
        eventTime > playProgress ? +eventTime - +playProgress : playProgress;

      return (
        <span className={classNames(styles.svgStyle)}>
          <TrendingRedIcon />
          <div className={styles.seen}>
            <div>{t('Still')}</div>
            <div>{timeRemained} min</div>
          </div>
        </span>
      );
    }

    return null;
  };

  const renderPlayProgress = () => {
    if (EventPlayProgress > 0 || (isActive && EventPlayProgress == 0)) {
      return (
        <div>
          <ProgressBar
            progress={EventPlayProgress}
            showProgressNumber={false}
            color={'#FB1E5B'}
            width={'100%'}
            height={'4px'}
            style={{ background: '#B2BCC6' }}
            className={styles.playProgress}
            isEventWatch={true}
          />
        </div>
      );
    }

    return null;
  };

  const renderMainAction = () => {
    if (isAdmin || (options && !options.showMainAction)) {
      return null;
    }

    const receptionPage = bindWebinarLink(
      URLS.webinar.reception.home,
      String(event.id ?? 0),
      `tool=${REGISTRATION_TAB}`,
    );
    let link = '';

    if (isActive) {
      const sessionPage = bindWebinarLink(
        URLS.webinar.webinarSessions.home,
        String(event.id ?? 0),
        `tool=${PROGRAM_TAB}`,
      );
      const eventQuickLink =
        +(event.slotsCount ?? 0) > 1 ? sessionPage : receptionPage;

      if (isExpired) {
        if (
          +event.replayStatus !== EVENT_REPLAY_STATUS.REPLAY_STATUS_ACTIVE ||
          isReplayExpiredForUser(event)
        ) {
          return null;
        }

        if (+event.slotsCount == 1 && event.slotReplayUrls) {
          const replayLink =
            getSlotReplayUrl(event.slotReplayUrls, language) +
            (event.selectedDate
              ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
              : '');

          if (replayLink) {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={replayLink}
                className={classNames(
                  styles.mainButton,
                  'greetings',
                  styles.blue,
                )}
                onClick={
                  isRegistered && !event['user-registered']
                    ? handleRegisterPremium
                    : stopPropagation
                }
              >
                {!registeringPremium ? (
                  <>
                    {isFullWatch ? (
                      <ReviewIcon
                        className="m-r-xxs"
                        width="12"
                        height="12"
                        fill="#FFF"
                      />
                    ) : watchedTime > 0 ? (
                      <ResumeIcon
                        className="m-r-xxs"
                        width="12"
                        height="12"
                        fill="#FFF"
                      />
                    ) : (
                      <PlayIcon
                        className="m-r-xxs"
                        width="12"
                        height="12"
                        fill="#FFF"
                      />
                    )}
                  </>
                ) : (
                  <div className="m-r-xxs">
                    <ClipLoader size={12} color="#FFFFFF" />
                  </div>
                )}

                {isFullWatch
                  ? t('Review')
                  : watchedTime > 0
                  ? t('Resume')
                  : t('Replay')}
              </a>
            );
          }
        }

        link = isEventFull ? sessionPage : eventQuickLink;
        return (
          <Link
            {...linkBlanckProps}
            to={link}
            className={classNames(styles.mainButton, 'greetings', styles.blue)}
            onClick={stopPropagation}
          >
            <PlayIcon className="m-r-xxs" width="12" height="12" fill="#FFF" />
            {t('Replay')}
          </Link>
        );
      }

      if (!isExpired) {
        link = isEventFull
          ? sessionPage
          : eventQuickLink +
            (multiDateIndex ? `&dateIndex=${multiDateIndex}` : '');
        return (
          <Link
            {...linkBlanckProps}
            to={link}
            className={classNames(
              styles.mainButton,
              'greetings',
              isLive ? styles.red : styles.blue,
            )}
            onClick={stopPropagation}
          >
            <ExternalLinkIcon width="10" height="10" className="m-r-xxs" />
            {isLive ? t('Join the live') : t('Rejoin')}
          </Link>
        );
      }

      if (!isFullWatch && EventPlayProgress == 0) {
        link = isEventFull ? sessionPage : eventQuickLink;
        return (
          <Link
            {...linkBlanckProps}
            to={link}
            className={classNames(styles.mainButton, 'greetings', styles.blue)}
            onClick={stopPropagation}
          >
            <ExternalLinkIcon width="10" height="10" className="m-r-xxs" />
            {t('Play')}
          </Link>
        );
      }
    } else {
      // !isActive: User is not registered !
      if (/*isExpired || */ !isEventRegistrationOpen(event as Event)) {
        return null;
      }

      const link = !isEmpty(codeCoupon)
        ? `${receptionPage}&coupon=${codeCoupon}${
            multiDateIndex ? `&dateIndex=${multiDateIndex}` : ''
          }`
        : getEventRegisterLink(event, hasCycle, multiDateIndex);
      return (
        <Link
          {...linkBlanckProps}
          to={link}
          className={classNames(styles.mainButton, 'greetings', styles.green)}
          onClick={stopPropagation}
        >
          {isFree || !isEmpty(codeCoupon) ? t('register') : t('Buy training')}
        </Link>
      );
    }

    return null;
  };

  const renderDetailsAction = () => {
    if (options && !options.showDetails) {
      return null;
    }

    const isEventFull = +(event.type ?? 0) == EVENT_TYPES.EVENT_FULL;

    let link = '';
    if (isEventFull) {
      link = bindWebinarLink(URLS.webinar.landingPage, String(event.id ?? 0));
    } else {
      link = bindWebinarLink(
        URLS.webinar.reception.home,
        String(event.id ?? 0),
        `${isEmpty(codeCoupon) ? '' : `&coupon=${codeCoupon}`}${
          multiDateIndex ? `&dateIndex=${multiDateIndex}` : ''
        }`,
      );
    }

    if (!handleEventRedirect) {
      return (
        <Link
          {...linkBlanckProps}
          to={link}
          className={classNames(styles.mainButton, 'greetings', styles.details)}
          onClick={stopPropagation}
        >
          {t('Details')}
        </Link>
      );
    } else {
      return (
        <button
          className={classNames(styles.mainButton, 'greetings', styles.details)}
          onClick={() => handleEventRedirect(event, link)}
        >
          {t('Details')}
        </button>
      );
    }
  };

  const renderAdminButton = () => {
    if (!isAdmin || (options && !options.showBackOffice)) {
      return null;
    }

    return (
      <a
        {...linkBlanckProps}
        href={`${TTP_EVENT_URL}/${language}/bo/${event.id}/index`}
        className={classNames(styles.mainButton, 'greetings', styles.admin)}
        onClick={stopPropagation}
      >
        <IconSettings
          width="10"
          height="10"
          className="m-r-xxs"
          fill="#18a0fb"
        />
        {t('Back office')}
      </a>
    );
  };

  const renderEventPrice = () => {
    if (options && !options.showPrice) {
      return null;
    }

    if (isFree) {
      return <div className={styles.badge}>{t('Free')} </div>;
    }

    const eventPrice =
      (isMember ? memberPrice ?? nonMemberPrice : nonMemberPrice) ?? 0;

    if (!price || +price > +eventPrice) {
      return <div className={styles.badge}>{eventPrice} €</div>;
    }

    return (
      <div className={styles.badge}>
        <span className="m-r-xs">{price} €</span>
        <span className={styles.strike}>{eventPrice} €</span>
      </div>
    );
  };

  const renderSpeakers = () => {
    const speakersData = isCycle
      ? event['speakers']
      : event['speakers-abstract'];
    if (!speakersData) {
      return null;
    }

    const dataToFormat = isCycle ? speakersData : speakersData.speakers;
    const filteredSpeakers = filterSpeakersForSlots(dataToFormat);

    return <SpeakerSlide speakers={filteredSpeakers} isRegistered={isActive} />;
  };

  const renderTrainingMode = () => {
    if (!isWebinar && place) {
      return (
        <li>
          <MapPinIcon
            width="14"
            height="14"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>{place.split('contact')[0]}</span>
        </li>
      );
    }

    if (isWebinar && place) {
      return (
        <li>
          <HybridIcon
            width="14"
            height="14"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>{t('Virtual and Presential')}</span>
        </li>
      );
    }

    return (
      <li>
        <EarthIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
        <span>{t('Webinar')}</span>
      </li>
    );
  };

  const renderReplayDescription = () => {
    if (!isReplayable || (options && !options.showReplayInfo) || isRegistered) {
      return null;
    }

    if (isUpcomming || isLive) {
      return (
        <li style={{ lineHeight: '13px' }}>
          <PlayIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
          <span>
            {' '}
            {t('training_info_replay').replace(
              '{date}',
              dateEndOfReplay ? dateEndOfReplay : eventDateEndOfReplay,
            )}
          </span>
        </li>
      );
    }
  };
  const renderInReplayTitleWithDesc = () => {
    if (!isReplayable || (options && !options.showReplayInfo)) {
      return null;
    }

    if (isExpired) {
      return (
        <>
          <li style={{ lineHeight: '1.25rem' }}>
            <PlayIcon
              width="14"
              height="14"
              fill="#29394D"
              className="m-r-xs"
            />
            <span style={{ fontStyle: 'normal', fontWeight: 'bold' }}>
              {capFirstLetterInSentence(t('IN REPLAY'))}
            </span>
          </li>
          {!isRegistered && (
            <li style={{ lineHeight: '1rem', marginLeft: '1.35rem' }}>
              <span>
                {' '}
                {t('training_info_replay').replace(
                  '{date}',
                  dateEndOfReplay ? dateEndOfReplay : eventDateEndOfReplay,
                )}
              </span>
            </li>
          )}
        </>
      );
    }
  };

  const renderCertificateStatus = () => {
    let certificateMessage = (
      <span>
        {t('Certificate included approved by')} <b>&thinsp;ITAA</b>
      </span>
    );

    let replayLink: string | null | undefined = '';
    if (+event.slotsCount == 1 && event.slotReplayUrls) {
      replayLink = getSlotReplayUrl(event.slotReplayUrls, language);
    }

    if (isExpired) {
      switch (certificateStatus as EVENT_CERTIFICATE_STATUS) {
        case EVENT_CERTIFICATE_STATUS.CERTIFICATE_EXAMEN_PASSED:
          if (replayLink) {
            certificateMessage = (
              <span>
                <a href={replayLink} target="_blank" rel="noopener noreferrer">
                  {t('Take the exam for certification')}
                </a>
              </span>
            );
          }
          break;
        case EVENT_CERTIFICATE_STATUS.CERTIFICATE_PENDING_EXPORT:
          certificateMessage = (
            <span>
              <Markdown>{t('Certificate pending export on the ITAA')}</Markdown>
            </span>
          );
          break;
        case EVENT_CERTIFICATE_STATUS.CERTIFICATE_EXPORTED:
          certificateMessage = (
            <span>
              <Markdown>
                {t('Certificate exported on the ITAA website')}
              </Markdown>
            </span>
          );
          break;
      }
    }

    return (
      <li>
        <AwardIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
        <span>
          {event.isCertificateNotIncluded ? (
            <span>{t('Certificate not included')}</span>
          ) : isCycle &&
            approvedBy &&
            (!certificateStatus ||
              (!replayLink &&
                certificateStatus ==
                  EVENT_CERTIFICATE_STATUS.CERTIFICATE_EXAMEN_PASSED)) ? (
            <Markdown>{approvedBy}</Markdown>
          ) : (
            certificateMessage
          )}
        </span>
      </li>
    );
  };

  const handleOnMouseEnter = () => {
    setShowCyclesIcons(true);
    setHovered(true);
  };
  const handleOnMouseLeave = () => {
    setShowCyclesIcons(false);
    if (!showAddTags) {
      setHovered(false);
    }
  };

  return (
    <div
      className={classNames(
        styles.event,
        isActive && styles.active,
        onClick && styles.clickable,
      )}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={onClick}
      ref={mainRef}
    >
      <div>{renderPlayProgressFlag()}</div>
      <div
        className={
          EventPlayProgress > 0 || (isActive && EventPlayProgress == 0)
            ? ''
            : 'm-b-s'
        }
      >
        <div
          className={classNames(styles.banner)}
          style={{ backgroundImage: `url(${bannerImgUrl})` }}
        >
          {!isEmpty(event.clientData?.avatarUrl) && (
            <div className={styles.logoWrapper}>
              <span
                style={{
                  backgroundImage: `url(${event.clientData?.avatarUrl})`,
                }}
              />
            </div>
          )}

          <>
            <div
              className={styles.cycleIcon}
              style={!isEventInSeason ? { display: 'none' } : {}}
            >
              {showCyclesIcons ? (
                <>
                  <SeasonDescriptionIcon />
                  <span
                    className={styles.cycleDescription}
                    style={language == 'nl' ? seasonIconStyle : {}}
                  >
                    {t('included_in_season')}
                  </span>
                </>
              ) : (
                <SeasonIcon />
              )}
            </div>
            <div
              className={styles.cycleIcon}
              style={
                isEventInCycle && !isEventInSeason
                  ? { bottom: '0' }
                  : isEventInCycle && isEventInSeason
                  ? { bottom: '31px' }
                  : { display: 'none' }
              }
            >
              {showCyclesIcons ? (
                <>
                  <CycleDescriptionIcon />
                  <span
                    className={styles.cycleDescription}
                    style={
                      language == 'nl'
                        ? { ...cycleIconStyle, color: '#5F5DE8' }
                        : { color: '#5F5DE8' }
                    }
                  >
                    {t('included_in_cycle')}
                  </span>
                </>
              ) : (
                <CycleIcon />
              )}
            </div>
          </>
          {event.isIncludedPremium && !isActive ? (
            <PremiumFlag className={styles.premiumFlag} />
          ) : (
            ''
          )}
          {label && (
            <div
              className={styles.labels}
              style={
                isEventInCycle && isEventInSeason
                  ? { marginBottom: '75px' }
                  : isEventInCycle || isEventInSeason
                  ? { marginBottom: '45px' }
                  : {}
              }
            >
              <div className={styles.label}>
                <HelpIcon
                  height="14px"
                  width="14px"
                  fill="#FFF"
                  className="m-r-xs"
                />
                <span>{label}</span>
              </div>
            </div>
          )}
          {isActive && EventPlayProgress == 0 ? (
            <div className={styles.badges}>
              <div className={styles.badge}>{`${0} sur ${eventTime} min`}</div>
            </div>
          ) : !isEventFull && EventPlayProgress <= 0 ? (
            <div className={styles.badges}>
              {renderEventPrice()}
              {/*<div className={styles.badge}>{formatDecimalHours(nbHours)}</div>*/}
              <div className={styles.badge}>{nbMinutes} min</div>
            </div>
          ) : isFullWatch ? (
            <div className={styles.badges}>
              <div className={classNames(styles.badge, styles.greenBadge)}>
                {`${playProgress} sur ${eventTime} min`}
              </div>
            </div>
          ) : (
            EventPlayProgress > 0 && (
              <div className={styles.badges}>
                <div className={classNames(styles.badge, styles.redBadge)}>
                  {`${playProgress} sur ${eventTime} min`}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div>{renderPlayProgress()}</div>

      <h3>
        <Shave maxHeight={45}>{name}</Shave>
      </h3>
      <div
        className={classNames(
          styles.speakers,
          options && !options.showReplayInfo && 'm-t-s',
        )}
      >
        {renderSpeakers()}
      </div>
      <div
        className={classNames(
          styles.infos,
          options && !options.showReplayInfo && 'm-t-xs',
        )}
      >
        <ul>
          <li>
            {isLive ? (
              <>
                <LiveIcon className="m-r-xs" size="14px" />
                <span style={{ color: '#fe3745' }}>
                  <strong>{capFirstLetterInSentence(t('ongoing_now'))}</strong>
                </span>
              </>
            ) : (
              isUpcomming && (
                <>
                  <CalendarIcon
                    width="14"
                    height="14"
                    fill="#29394D"
                    className="m-r-xs"
                  />
                  <span>
                    <strong>
                      {!isWebinar && place
                        ? t('onSite')
                        : capFirstLetterInSentence(t('LIVE'))}{' '}
                      :
                    </strong>
                    &thinsp;
                    {dateHelper}
                  </span>
                </>
              )
            )}
          </li>
          {renderInReplayTitleWithDesc()}
          {renderTrainingMode()}
          {renderReplayDescription()}
          {renderCertificateStatus()}
        </ul>
      </div>
      <div className="m-t-auto">
        <div className={styles.mainActions}>
          {renderMainAction()}
          {renderDetailsAction()}
          {renderAdminButton()}
        </div>
      </div>

      {isAdmin && hovered && (
        <EventLayoutHover
          setShowAddTags={setShowAddTags}
          showAddTags={showAddTags}
        />
      )}
      {isAdmin && showAddTags && (
        <TagsForm setShowAddTags={setShowAddTags} eventId={event.id} />
      )}
    </div>
  );
};

EventLayout1.Fetching = () => (
  <div className={classNames(styles.event, styles.fetching)}>
    <div className={classNames(styles.banner, 'm-b-m')}></div>
    <h3 />
    <div className={classNames(styles.speakers, 'greetings')}>
      <h6></h6>
    </div>
    <div className={styles.infos}>
      <ul>
        <li>
          <CalendarIcon
            width="14"
            height="14"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>
            <h3></h3>
          </span>
        </li>
        <li>
          <EarthIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
          <span>
            <h3></h3>
          </span>
        </li>
      </ul>
    </div>
    <div className="m-t-auto">
      <div className={styles.mainActions}></div>
    </div>
  </div>
);

EventLayout1.FetchingLayout2 = () => (
  <div className={classNames(styles.event, styles.fetching_layout2)}>
    <div className={classNames(styles.banner, 'm-b-m')} />
    <h2 />

    <h6 />

    <div className={styles.infos}>
      <ul>
        <li>
          <span className={styles.icon_ph} />
          <span>
            <h3 />
          </span>
        </li>
        <li>
          <span className={styles.icon_ph} />
          <span>
            <h3 />
          </span>
        </li>
      </ul>
    </div>
    <div className="m-t-auto">
      <div className={styles.mainActions}></div>
    </div>
  </div>
);

export default EventLayout1;

import axios from 'axios';
import { TTP_API_URL } from 'config';
import { Certificate } from 'interfaces/Certificate';
import { PartnerGuestMailType } from 'interfaces/PartnerGuest';
import { Filter } from 'services/Filter';
import { hydrateFormData } from 'store/services';
import { ResourceData, TTPSort } from 'store/types';
import { Guest } from './types';

export const getGuests = ({
  token,
  filters = [],
  sort = [],
}: {
  token: string;
  sort?: TTPSort[];
  filters?: Filter[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest`;

  const filter = [...filters].map((filter) => filter.serialize());

  const sortBy = [...sort];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sortBy),
      nolimit: 1,
    },
  });
};

export const getGuestData = ({
  token,
  filters,
}: {
  token: string;
  filters: Filter[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/getbyeventanduser`;

  const filter = [...filters].map((filter) => filter.serialize());

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};

export const getGuestByCycleAndUser = ({
  token,
  cycleId,
  userId,
}: {
  token: string;
  cycleId: string | number;
  userId: string | number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/getbycycleanduser`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      cycleId,
      userId,
    },
  });
};

/// ------------------ EVENT LIGHT REGISTRATION ------------------ ///

export const acceptRegistration = ({
  token,
  eventId,
  userId,
  answer,
}: {
  token: string;
  eventId: number;
  userId: number;
  answer?: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/register/accept`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      eventId,
      userId,
      fromBackOffice: 0,
      ...(answer ? { answer } : {}),
    },
  });
};

export const declineRegistration = ({
  token,
  eventId,
  userId,
}: {
  token: string;
  eventId: number;
  userId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/register/decline`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      eventId,
      userId,
      fromBackOffice: 0,
    },
  });
};

/// ------------------ EVENT REGISTRATION ------------------ ///

export const saveStep1 = ({
  token,
  eventId,
  userId,
  guestData,
}: {
  token: string;
  eventId: string;
  userId: string;
  guestData: ResourceData<Guest>;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/savestep1`;

  const formData = new FormData();
  formData.append('access_token', token);

  hydrateFormData(formData, guestData);
  formData.append('event', eventId);
  formData.append('user', userId);

  return axios.post(requestUrl, formData);
};

export interface Step2Data {
  user: number;
  cycle: number;
  event: number;
  plan?: number;
  options?: string; // "[421,423,428]"
  billingCompanyNumber: string;
  billingOrganization: string;
  // billingWebOrganization: string;
  billingStreet: string;
  billingPostalCode: string; // '1360 Perwez';
  billingSubjectToVAT: boolean | 1 | 0 | '1' | '0';
  billingAddress2?: string;
  billingOrderNumber?: string;
  billingCountry?: string;
  coupons?: string; //coupon1,coupon2
  coupon?: string;
  termsOfSales: boolean | 1 | 0 | '1' | '0';
  days?: string[]; // ["01/11/2020", "02/11/2020"]
  deliveryAddressId?: number;
  frequency: string;
  selectedDate?: string;
  response: string;
}

export const saveStep2 = ({
  token,
  eventId,
  userId,
  data,
}: {
  token: string;
  eventId: string;
  userId: string;
  data: ResourceData<Step2Data>;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/register/save-choices-step`;

  const formData = new FormData();
  formData.append('access_token', token);

  hydrateFormData(formData, data);
  formData.append('event', eventId);
  formData.append('user', userId);

  return axios.post(requestUrl, formData);
};

export const registerPremiumToEvent = ({
  token,
  eventId,
  userId,
  cycleId,
}: any) => {
  const requestUrl = `${TTP_API_URL}/event/guest/register/register_premium_event`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('event', eventId);
  formData.append('user', userId);
  formData.append('cycle', cycleId);

  return axios.post(requestUrl, formData);
};

/// ------------------ COUPON ------------------ ///

export const checkCoupon = ({
  token,
  eventId,
  cycleId,
  guestId,
  code,
}: {
  token: string;
  guestId: number;
  eventId?: number;
  cycleId?: number;
  code: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/coupon/check`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('code', code);
  if (eventId) {
    formData.append('eventId', String(eventId));
  }
  if (cycleId) {
    formData.append('cycleId', String(cycleId));
  }
  formData.append('idGuest', String(guestId));

  return axios.post(requestUrl, formData);
};

/// ------------------ ADDRESS ------------------ ///

export const deleteAddress = ({
  token,
  userId,
  signature,
}: {
  token: string;
  userId: number;
  signature: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/billing/forget-signature`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      userId,
      signature,
    },
  });
};

/// ------------------ PARTNER GUESTS ------------------ ///

export const sendPartnerGuestsEmail = ({
  token,
  eventId,
  partnerId,
  ids,
  level,
}: {
  token: string;
  eventId: string;
  partnerId: string;
  ids?: number[];
  level?: PartnerGuestMailType;
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest/send-email`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);
  formData.append('partnerId', partnerId);

  if (ids) {
    formData.append('ids', JSON.stringify(ids));
  }

  if (level) {
    formData.append('level', level);
  }

  return axios.post(requestUrl, formData);
};

export const invitePartnerSpeaker = ({
  token,
  eventId,
  partnerId,
  couponModelId,
}: {
  token: string;
  eventId: string;
  partnerId: string;
  couponModelId: number | string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest/add-speakers`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);
  formData.append('partnerId', partnerId);
  formData.append('couponModelId', String(couponModelId));

  return axios.post(requestUrl, formData);
};

export const getPartnerGuestBadges = ({
  token,
  eventId,
  ids,
  send,
  download,
}: {
  token: string;
  eventId: string;
  send?: boolean;
  download?: boolean;
  ids: number[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/print-download-badges/${eventId}`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);

  if (send) {
    formData.append('send', '1');
  }
  if (download) {
    formData.append('download', '1');
  }
  formData.append('ids', JSON.stringify(ids));

  return axios.post(requestUrl, formData);
};

export const savePartnerGuest = ({
  token,
  guestId,
  eventId,
  partnerId,
  data,
}: {
  token: string;
  guestId: string | null;
  eventId: string;
  partnerId: string;
  data: any;
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest`;

  const formData = new FormData();
  formData.append('access_token', token);

  if (guestId != null) {
    formData.append('id', guestId);
  }
  hydrateFormData(formData, data);
  formData.append('event', eventId);
  formData.append('partner', partnerId);
  return axios.post(requestUrl, formData);
};

export const importPartnerGuests = ({
  token,
  eventId,
  partnerId,
  data,
  file,
}: {
  token: string;
  eventId: string;
  partnerId: string;
  data: any;
  file: any;
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest/import-command`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);
  formData.append('partnerId', partnerId);
  formData.append('data', data);

  if (file instanceof File) {
    formData.append('file', file, file.name);
  }
  return axios.post(requestUrl, formData);
};

export const checkImportGuestsCommandStatus = ({
  token,
  commandId,
}: {
  token: string;
  commandId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/command/${commandId}`;

  const formData = new FormData();
  formData.append('access_token', token);

  return axios.post(requestUrl, formData);
};

export const getInvitationPreview = ({
  token,
  eventId,
  partnerGuestId,
}: {
  token: string;
  eventId: string;
  partnerGuestId: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest/preview-email`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);
  formData.append('partnerGuestId', partnerGuestId);

  return axios.post(requestUrl, formData);
};

export const deletePartnerGuest = ({
  token,
  partnerGuestId,
}: {
  token: string;
  partnerGuestId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest/delete/${partnerGuestId}`;

  const formData = new FormData();
  formData.append('access_token', token);

  return axios.post(requestUrl, formData);
};

export const forceRegistration = ({
  token,
  eventId,
  partnerId,
  partnerGuestIds,
}: {
  token: string;
  eventId: string;
  partnerId: string;
  partnerGuestIds: number[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/partner-guest/force-registration`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('partnerId', partnerId);
  formData.append('eventId', eventId);
  formData.append('sendConfirmationEmail', '1');
  formData.append('ids', JSON.stringify(partnerGuestIds));
  return axios.post(requestUrl, formData);
};

/// ------------------ GENERATE BADGE ------------------ ///

export const generateBadge = ({
  token,
  guestId,
  eventId,
}: {
  token: string;
  guestId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/get-badge-pdf`;

  return axios.get(requestUrl, {
    params: {
      accessToken: token,
      event: eventId,
      guest: guestId,
    },
  });
};

export const sendBadge = ({
  token,
  guestId,
  eventId,
}: {
  token: string;
  guestId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/send-badge`;

  return axios.get(requestUrl, {
    params: {
      accessToken: token,
      event: eventId,
      guest: guestId,
    },
  });
};

export const sendGuestConfirmationSubscribed = ({
  token,
  guestsId,
}: {
  token: string;
  guestsId: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/send-confirmation-email`;

  const formData = new FormData();
  formData.append('accessToken', token);
  formData.append('guestsIds[]', guestsId);
  return axios.post(requestUrl, formData);
};

/// ------------------ CERTIFICATE ------------------ ///

export const getCertificateForEventAndGuest = ({
  token,
  guestId,
  eventId,
}: {
  token: string;
  guestId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/certificate/get-for-event-for-guest/${eventId}/${guestId}`;

  return axios.get(requestUrl, {
    params: {
      accessToken: token,
    },
  });
};

export const generateCertificate = ({
  token,
  certificate,
}: {
  token: string;
  certificate: Certificate;
}) => {
  const requestUrl = `${TTP_API_URL}/event/certificate/create-certificate-pdf`;
  const { id, event, guest, day } = certificate;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('id', String(id));
  formData.append('event', String(event));
  formData.append('guest', String(guest));
  formData.append('day', day);

  return axios.post(requestUrl, formData);
};

export const sendCertificate = ({
  token,
  certificateId,
}: {
  token: string;
  certificateId: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/certificate/send-existing-certificate`;

  const formData = new FormData();
  formData.append('access_token', token);

  return axios.post(requestUrl, formData, { params: { id: certificateId } });
};

export const editCertificate = ({
  token,
  certificateData,
}: {
  token: string;
  certificateData: ResourceData<Certificate>;
}) => {
  const requestUrl = `${TTP_API_URL}/event/certificate/${certificateData.id}`;

  const formData = new FormData();
  formData.append('access_token', token);
  hydrateFormData(formData, certificateData);

  return axios.post(requestUrl, formData);
};

/// ------------------ CONFIRM GUEST OPTION ------------------ ///

export const confirmGuestOption = ({
  token,
  guest,
  option,
  hasConfirmed,
}: {
  token: string;
  guest: number;
  option: number;
  hasConfirmed: boolean;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest-option`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('option', String(option));
  formData.append('guest', String(guest));
  formData.append('hasConfirmed', String(+hasConfirmed));

  return axios.post(requestUrl, formData);
};

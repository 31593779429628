import React, { memo, useEffect, useState } from 'react';
import styles from './SubMenu.module.scss';
import TTNavComponent from './TTNavComponent';
import classnames from 'classnames';
import _ from 'i18n';
import { Link, useLocation } from 'react-router-dom';
import { MenuDirectionType } from 'store/Params/SubMenu';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as MySpaceIcon } from 'assets/icons/channels.svg';
import { ReactComponent as SeasonsIcon } from 'assets/icons/layers.svg';
import { ReactComponent as CongressIcon } from 'assets/icons/congress.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/inbox.svg';
import { URLS } from 'router';
import { Organization, TTPSort } from 'store/types';
import {
  bindWebinarLink,
  bindPremiumOfferLink,
  getCommunityRoute,
  CYCLE_PREMIUM_ID,
  bindCycleLink,
} from 'utils';
import { User } from 'store/Auth/types';
import TTSelectComponent from './TTSelectComponent';
import { ReactComponent as TvIcon } from 'assets/icons/tv.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/cil_education.svg';
import { REGISTRATION_TAB } from 'Cycle/Tools/services';
import { CURRENT_CONGRESS_ID } from 'config';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import ShoppingCart from './ShoppingCart/ShoppingCart';
import { Order } from 'store/Guests/types';
import { ResourceState } from 'store/Resource/types';
import { EventAccess } from 'store/Event/types';
import { Filter } from 'services/Filter';

interface SubmenuTabsConfig {
  showTrainingsTab: boolean;
  showSeasonsTab: boolean;
  showChannelsTab: boolean;
  showMySpaceTab: boolean;
  showCyclesTab: boolean;
  showEssentialsTab: boolean;
  showPlansBtn: boolean;
  showPremiumOfferBtn: boolean;
  showShoppingCart: boolean;
  showPremiumTab: boolean;
}

interface Props {
  direction: MenuDirectionType;
  tabsConfig: SubmenuTabsConfig;
  innerRef?: React.Ref<any>;
  navCommunity?: Organization | null;
  user?: User | null;
  premiumOfferId?: number | null;
  orders: Order[];
  isCartFetching: boolean;
  changeMenuDirection: () => void;
  cancelOrder: (guestId: number, orderId: number) => any;
  fetchUserOrders: (
    userId: number,
    filters?: Filter[],
    sort?: TTPSort[],
  ) => void;
}

export default memo(function SubMenu({
  direction,
  innerRef,
  navCommunity,
  user,
  tabsConfig,
  premiumOfferId,
  orders,
  isCartFetching,
  changeMenuDirection,
  cancelOrder,
  fetchUserOrders,
}: Props) {
  const [channelsUrl, setChannelsUrl] = useState<string[]>([URLS.channels]);
  const {
    showTrainingsTab,
    showSeasonsTab,
    showChannelsTab,
    showMySpaceTab,
    showCyclesTab,
    showEssentialsTab,
    showPlansBtn,
    showPremiumOfferBtn,
    showShoppingCart,
    showPremiumTab,
  } = tabsConfig;
  const libraryItems =
    +showSeasonsTab +
    +showTrainingsTab +
    +showCyclesTab +
    +showPremiumTab +
    +showEssentialsTab;
  const isHorizontal = direction === 'HORIZONTAL';
  const isVertical = direction === 'VERTICAL';
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('channel/')) {
      const channelId = pathname.substring(pathname.lastIndexOf('/') + 1);
      setChannelsUrl([...channelsUrl, '/channel/' + channelId]);
    }
  }, [pathname]);

  useEffect(() => {
    const offCanvasDiv = document.querySelector('.off-canvas-wrapper');

    if (offCanvasDiv) {
      if (direction === 'VERTICAL') {
        offCanvasDiv.classList.add('marginAppLeft');
      } else {
        offCanvasDiv.classList.remove('marginAppLeft');
      }
    }

    return () => {
      const offCanvasDiv = document.querySelector('.off-canvas-wrapper');

      if (offCanvasDiv) {
        offCanvasDiv.classList.remove('marginAppLeft');
      }
    };
  }, [direction]);

  return (
    <div
      id="sub-menu"
      ref={innerRef}
      className={classnames(
        styles.submenu,
        direction === 'VERTICAL' ? styles.vertical : '',
      )}
    >
      <span className={styles.flip} onClick={changeMenuDirection}>
        <i className="icon icon-menu" />
      </span>
      <ul>
        <div className={styles.left_side}>
          <li className={styles.nav}>
            <TTNavComponent
              label={_('Home')}
              url={getCommunityRoute(navCommunity)}
              icon={() => <GridIcon />}
              exact={true}
            />
          </li>
          {libraryItems > 1 && (
            <li className={styles.nav}>
              <TTSelectComponent
                url={URLS.library.root}
                isClickable={false}
                label={_('catalog').toUpperCase()}
                icon={() => <InboxIcon width="20" height="20" fill="#C7E8FE" />}
                rows={[
                  {
                    label: _('Trainings').toUpperCase(),
                    url: URLS.library.trainings,
                    show: showTrainingsTab,
                  },
                  {
                    label: _('Cycles').toUpperCase(),
                    url: URLS.library.cycles,
                    show: showCyclesTab,
                  },
                  {
                    label: _('Essentials').toUpperCase(),
                    url: URLS.library.essentials,
                    show: showEssentialsTab,
                  },
                  {
                    label: _('seasons').toUpperCase(),
                    url: URLS.library.seasons,
                    show: showSeasonsTab,
                  },
                  {
                    label: 'Premium'.toUpperCase(),
                    url: bindPremiumOfferLink(
                      URLS.premiumOffer.root,
                      CYCLE_PREMIUM_ID,
                    ),
                    show: showPremiumTab,
                  },
                ]}
              />
            </li>
          )}
          {libraryItems < 2 && showTrainingsTab && user && (
            <li className={styles.nav}>
              <TTNavComponent
                label={_('Trainings')}
                url={URLS.library.trainings}
                icon={() => (
                  <EducationIcon width="20" height="20" fill="#C7E8FE" />
                )}
                exact={true}
              />
            </li>
          )}
          {libraryItems < 2 && showSeasonsTab && user && (
            <li className={styles.nav}>
              <TTNavComponent
                label={_('seasons')}
                url={URLS.library.seasons}
                icon={() => (
                  <SeasonsIcon width="20" height="20" fill="#C7E8FE" />
                )}
                exact={true}
              />
            </li>
          )}
          <li className={styles.nav}>
            <TTNavComponent
              label={_('VIRTUAL CONGRESS').toUpperCase()}
              url={bindWebinarLink(
                URLS.webinar.landingPage,
                CURRENT_CONGRESS_ID,
                `tool=${REGISTRATION_TAB}`,
              )}
              icon={() => (
                <CongressIcon width="20" height="20" fill="#C7E8FE" />
              )}
              exact={true}
            />
          </li>
          {showChannelsTab && (
            <li className={styles.nav}>
              <TTNavComponent
                label={_('Channels')}
                url={URLS.channels}
                icon={() => <TvIcon width="20" height="20" stroke="#C7E8FE" />}
                isActive={() => channelsUrl.includes(pathname)}
              />
            </li>
          )}
          {/* <li className={styles.nav}>
            <TTNavComponent
              label={_('Speakers')}
              url={URLS.speakers}
              icon={() => (
                <UsersIcon
                  width="20"
                  height="20"
                  stroke="#C7E8FE"
                  strokeWidth="0.2"
                />
              )}
              exact={true}
            />
          </li> */}
          {showMySpaceTab && (
            <li className={styles.nav}>
              <TTSelectComponent
                url={URLS.mySpace.root}
                label={_('My space')}
                icon={() => (
                  <MySpaceIcon width="20" height="20" fill="#C7E8FE" />
                )}
                rows={[
                  {
                    label: _('My trainings').toUpperCase(),
                    url: URLS.mySpace.trainings,
                    show: true,
                  },
                  {
                    label: _('My documents').toUpperCase(),
                    url: URLS.mySpace.documents,
                    show: true,
                  },
                ]}
              />
            </li>
          )}
        </div>
        <div className={styles.right_side}>
          {showPlansBtn && isHorizontal && (
            <li className={styles.nav_btn}>
              <Link to={URLS.plans}>{_('offers_title')}</Link>
            </li>
          )}
          {showPremiumOfferBtn && isHorizontal && (
            <li className={styles.nav_btn}>
              <Link
                className={styles.redOrange}
                to={bindCycleLink(
                  URLS.cycle.reception.registration.root,
                  premiumOfferId ?? '',
                )}
              >
                <CheckMarkIcon width={10} height={10} fill="#fff" />{' '}
                <span className="m-l-xs">{_('Premium member')}</span>
              </Link>
            </li>
          )}
          {showShoppingCart && (
            <li className={classnames(styles.shopping_cart, 'm-x-xs')}>
              <ShoppingCart
                orders={orders}
                fetching={isCartFetching}
                cancelOrder={cancelOrder}
                fetchUserOrders={fetchUserOrders}
                user={user}
                contentClassName={styles.cart_content}
                wrapperClassName={styles.cart_wrapper}
                badgeClassName={styles.cart_badge}
                labelClassName={styles.cart_label}
                isExpanded={isVertical}
              />
            </li>
          )}
        </div>
      </ul>
    </div>
  );
});

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as lng } from './Language';
import { reducer as sourcesTokens } from './SourceToken';
import { reducer as subMenuDirection } from './SubMenu';
import { reducer as goBack } from './GoBack';
import { reducer as notificationBar } from './NotificationBar';
import { reducer as dialog } from './TTPDialog';
import { reducer as flashMessage } from './FlashMessage';
import { reducer as faq } from './Faq';
import { reducer as UpComingEventPopup } from './UpComingEventPopup';
import { reducer as appInfo } from './AppInfo';
import { reducer as channelInfo } from './ChannelInfo';

const paramsPersistConfig = {
  key: 'params',
  storage,
  whitelist: ['subMenuDirection', 'lng'],
};

const reducer = persistReducer(
  paramsPersistConfig,
  combineReducers({
    appInfo,
    lng,
    sourcesTokens,
    subMenuDirection,
    goBack,
    notificationBar,
    dialog,
    flashMessage,
    faq,
    UpComingEventPopup,
    channelInfo,
  }),
);

export default reducer;

export type ParamsState = ReturnType<typeof reducer>;

import { RootState } from 'store/types';

export const selectShowFaq = (state: RootState) => {
  return state.params.faq.showFaq ?? null;
};

export const selectSubMenuDirection = (state: RootState) => {
  return state.params.subMenuDirection;
};

export const selectShowNotificationBar = (state: RootState) => {
  return state.params.notificationBar.isDisplayed;
};

export const selectUpComingEventPopupCounter = (state: RootState) => {
  return state.params.UpComingEventPopup.popupShowCounter;
};

export const selectChannelInfo = (state: RootState) => {
  return state.params.channelInfo;
};

export const selectShowGoBack = (state: RootState) => {
  return state.params.goBack.showGoBack;
};

export const selectAppInfo = (state: RootState) => {
  return state.params.appInfo;
};

import React, { useCallback, useState } from 'react';
import s from './OptionsConfirmation.module.scss';
import t from 'i18n';
import { Language } from 'store/types';
import { User } from 'store/Auth/types';
import { Event } from 'store/Events/types';
import { isEmpty } from 'lodash';
import { Guest } from 'store/Guests/types';
import { getByLanguage } from 'utils';
import SmallOption from 'ui/SmallOption';
import { S3_FOLDER_URL } from 'config';
import cn from 'classnames';
import StepsControl from 'Webinar/Reception/Registration/Common/StepsControl/StepsControl';
import NextStep from 'Registration/NextStep/NextStep';

interface Props {
  onCloseModal: () => void;
  event: Event;
  user: User;
  language: Language;
  guest: Guest;
  confirmGuestOption: (
    guestId: number,
    optionId: number,
    isConfirmed: boolean,
  ) => any;
  fetchGuestData: (eventId: string | number, userId: string | number) => void;
}

export const OptionsConfirmation = ({
  onCloseModal,
  event,
  user,
  guest,
  language,
  confirmGuestOption,
  fetchGuestData,
}: Props) => {
  const options = guest?.options;
  const nonConfirmedOptions = options?.filter((obj) => {
    return obj.hasConfirmed == null;
  });

  const getGuestData = useCallback(() => {
    onCloseModal();
    if (user) {
      fetchGuestData(event.id, user.id);
    }
  }, [event.id, fetchGuestData, user]);

  const renderOptions = () => {
    return (
      <>
        {nonConfirmedOptions?.map((option) => {
          const name = getByLanguage(option, 'name', language);
          const pictureUrl = getByLanguage(option, 'pictureUrl', language);
          const description = getByLanguage(option, 'description', language);

          return (
            <div className="cell small-6 m-b-xs p-r-xs" key={option.id}>
              <SmallOption
                guestId={guest.id}
                optionId={option.id}
                confirmGuestOption={confirmGuestOption}
                title={name}
                description={description}
                imgUrl={
                  isEmpty(pictureUrl)
                    ? undefined
                    : `${S3_FOLDER_URL}/events-folder${pictureUrl}`
                }
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={cn(s.options_confirmation)}>
      <div className={cn(s.header, 'align-justify')}>
        <div className={s.title}>Confirmations d’options</div>
      </div>
      <div
        className="align-middle p-x-l m-t-s"
        style={{ height: '60vh', overflowY: 'scroll' }}
      >
        <div className={cn('grid-x')}>
          <div className={cn('cell small-12 m-b-xs p-r-xs', s.subheader)}>
            <h3>{t('My options')}</h3>
            <p>{t('options_description')}</p>
          </div>
          {renderOptions()}
        </div>
      </div>
      <StepsControl className={s.actions}>
        <div className={cn('p-s p-x-l flex-container')}>
          <div className="flex-container m-l-auto align-middle">
            <NextStep
              className="m-l-auto"
              text={t('Close')}
              hideIcon
              onClick={getGuestData}
            />
          </div>
        </div>
      </StepsControl>
    </div>
  );
};

export default OptionsConfirmation;

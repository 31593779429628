import { Dispatch } from 'redux';
import { RootState } from 'store/types';
import actions from './actions';
import * as api from './api';

export const fetchCollaborators = (organizationId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters = [
    {
      property: 'role.organization',
      operator: 'eq',
      value: organizationId,
    },
  ];

  return dispatch(
    actions.fetchUsers(
      api.getCollaborators({ token, filters, organizationId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const getEmployeePack = (organizationId: number, eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;
  return dispatch(
    actions.fetchCollaborators(
      api.getEmployeePack({ token, organizationId, eventId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const saveCollaborators = (
  organization: number,
  email: string,
  firstName: string,
  lastName: string,
  role: any,
  phone: string,
  lng: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveCollaborators(
      api
        .saveCollaborators({
          token,
          organization,
          email,
          firstName,
          lastName,
          role,
          phone,
          lng,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const updateCollaborator = (
  user: number,
  organization: number,
  email: string,
  emailId: number,
  firstName: string,
  lastName: string,
  role: any,
  roleId: number,
  phone: string,
  phoneId: number,
  lng: any,
  isEdit?: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveCollaborators(
      api
        .updateCollaborator({
          token,
          user,
          organization,
          email,
          emailId,
          firstName,
          lastName,
          role,
          roleId,
          phone,
          phoneId,
          lng,
          isEdit,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const deleteCollaborator = (
  userId: number,
  organization: number,
  isSimple: boolean,
  email: string,
  oldEmail: string,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.deleteCollaborator(
      api
        .deleteCollaborator({
          token,
          userId: userId,
          organization: organization,
          isSimple: isSimple,
          email: email,
          oldEmail: oldEmail,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const uploadCollaborators = (
  data: any,
  organization: number,
  importAttachment: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.uploadCollaborators(
      api
        .uploadCollaborators({
          token,
          data: data,
          organization: organization,
          importAttachment,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const getCollaboratorByEmail = (email: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters = [
    {
      property: 'email',
      operator: 'eq',
      value: email,
    },
  ];

  return dispatch(
    actions.fetchUser(
      api.getCollaboratorByEmail({ token, filters, email }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};
